<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0">
        <div class="row praxisBackground text-Dunkelgrau">
          <div class="col-md-8 offset-md-2 mb-5 mt-5 align-items-center ps-5">
            <h1 class="mt-5">Impressum</h1>
            <p>Arbeitskreis für Mammillarienfreunde e.V.</p>
            <p>Uwe Lehmann Vorsitzender</p>
            <p>Alte Poststr. 48</p>
            <p>D 01471 Radeburg</p>
            <p>Tel. 035208-4765</p>
            <p>E-Mail: afm-datenschutz@mammillaria.eu</p>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
