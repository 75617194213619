<template>
<div>
  
  <carousel :items-to-show="1" :wrap-around="true" :autoplay="10000">
    <slide v-for="slide in slides" :key="slide">
      <img  class="img-fluid"
          :src="getImgUrl(slide)" 
          
      />
          </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes

import "../eigen_css/carouselHeader.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      "Header1Kopf",
      "Header2Kopf",
      "Header3Kopf",
      "Header4Kopf",
      "Header5Kopf",
    ];
    return {
      slides,
    };
  },
};
</script>
