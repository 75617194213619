<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1 v-if="sprachID === 0">Loseblattsammlung</h1>
        <h1 v-if="sprachID === 1">loose-leaf collection</h1>
      </div>
    </div>

    <div class="div stickyShop">
      <ul class="noDeko">
        <li class="borderRund p-3" @click="openBestellung()">
          <h2 v-if="sprachID === 0" class="bold">Bestellformular</h2>
          <h2 v-if="sprachID === 0" class="bold">Hier</h2>
          <h2 v-if="sprachID === 1" class="bold">order form</h2>
          <h2 v-if="sprachID === 1" class="bold">Here</h2>
        </li>
      </ul>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 pt-5">
        <p v-if="sprachID === 0">
          Die Herausgabe der Loseblattsammlung durch den AfM ist die Fortsetzung
          der vorher durch die ZAG Mammillaria herausgegebenen 4 Lieferungen.
        </p>
        <p v-if="sprachID === 0">
          Die Pflanzen werden in 2 Farbbildern dargestellt (Porträt und
          Standortfoto). Eine Reproduktion der Abbildung in der Erstbeschreibung
          ist beigefügt, und die Samen werden in meist 3 REM-Fotos abgebildet.
        </p>
        <p v-if="sprachID === 1">
          The publication of the loose-leaf collection by the AfM is the continuation
           of the 4 deliveries previously published by the ZAG Mammillaria.
        </p>
        <p v-if="sprachID === 1">
          The plants are presented in 2 color images (portrait and
           location photo). A reproduction of the illustration in the first description
           is attached, and the seeds are shown in mostly 3 SEM photos.
        </p>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 mt-1 pt-md-5">
        <img src="../assets/loseblatt2.jpg" class="img-fluid zoom" alt="..." />
      </div>
      <div class="col-11 col-md-3 mt-1 pt-md-5">
        <img src="../assets/loseblatt3.jpg" class="img-fluid zoom" alt="..." />
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-12 col-md-8 m-0  pt-5">
        <ul class="noDeko">
          <li
            class="borderRund p-2"
            @click="openPDF('Vuepdf/GesamtregisterLBS.pdf')"
          >
            <span class="bold p-2" v-if="sprachID === 0"
              >Gesamtregister LBS 1991 - 2015 (2010 und 2011 sind nicht
              erschienen)</span
            >
            <span class="bold" v-if="sprachID === 0"
              >Total register LBS 1991 - 2015 (2010 and 2011 are not
               appeared)</span
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-4 m-0 pt-5">
        <h4 v-if="sprachID === 0">Je Art sind enthalten:</h4>
        <h4 v-if="sprachID === 1">Each type includes:</h4>
        <ul>
          <li v-if="sprachID === 0">Erstbeschreibung incl. lateinischer Diagnose</li>
          <li v-if="sprachID === 0">ggf. mit deutscher Übersetzung des fremdsprachigen Textes</li>
          <li v-if="sprachID === 0">Ergänzungen zur Erstbeschreibung</li>
          <li v-if="sprachID === 0">Angaben zur Verbreitung und Kultur</li>
          <li v-if="sprachID === 0">Anmerkungen zur Art</li>
          <li v-if="sprachID === 1">First description incl. Latin diagnosis</li>
          <li v-if="sprachID === 1">possibly with a German translation of the foreign-language text</li>
          <li v-if="sprachID === 1">Additions to the first description</li>
          <li v-if="sprachID === 1">Distribution and culture information</li>
          <li v-if="sprachID === 1">Notes on Art</li>
        </ul>
      </div>
      <div class="col-11 col-md-4 m-0 pt-5">
        <h4 v-if="sprachID === 0">Preisliste:</h4>
        <ul class="koUL noDeko">
          <li v-if="sprachID === 0">Vereinsmitglieder 9.-- €</li>
          <li v-if="sprachID === 0">Nichtmitglieder 11.-- €</li>
          <li v-if="sprachID === 0">Auf Rechnung zusätzlich 1.-- €</li>
          <li v-if="sprachID === 1">club members 9.-- €</li>
          <li v-if="sprachID === 1">non-members 11.-- €</li>
          <li v-if="sprachID === 1">On account additionally 1.-- €</li>
        </ul>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-5">
        <img src="../assets/loseblatt1.jpg" class="img-fluid zoom" alt="..." />
      </div>
      <div class="col-11 col-md-3 m-0 pt-5">
        <p v-if="sprachID === 0">Überzeugen Sie sich von der Qualität der LBS anhand der<br /></p>
        <p v-if="sprachID === 1">Convince yourself of the quality of the LBS using the<br /></p>
        <ul class="noDeko">
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/Loseblattbeispiel.pdf')"
          >
            <span class="bold">M. sinforosensis</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-8 m-0 pt-5"></div>
    </div>
  </div>

  <div class="row t-center justifyCenter pt-5 bg-WeissTransparent">
    <div class="col-11 col-md-3">
      <h4>
        <p v-if="sprachID === 0">Redakteur & Layout:</p>
        <p v-if="sprachID === 1">Editor & Layout:</p>
      </h4>
      <P>
        <span class="bold">Thomas Linzen</span> <br />
        Weizengrund 43<br />
        D 39167 Irxleben<br />
        Tel.: +49 (0)39204-64138<br />

        E-Mail:
        <a class="text-Schwarz" href="mailto:afm-LBS-Redaktion@mammillaria.eu"
          >afm-LBS-Redaktion@mammillaria.eu
        </a>
      </P>
    </div>
    <div class="col-md-4">
      <h4>
        <p v-if="sprachID === 0">
          Redaktionelle Mitarbeit, Übersetzungen, <br />Layoutkonzept:
        </p>
        <p v-if="sprachID === 1">Editorial work, translations, <br />layout concept:</p>
      </h4>
      <P>
        <span class="bold">Othmar Appenzeller</span> <br />
        Erikastraße 9<br />
        D 66424 Homburg/ Saar<br />
        Tel.: +49 (0)6841-2896<br />

        E-Mail:
        <a class="text-Schwarz" href="mailto:afm-LBS-Redaktion@mammillaria.eu"
          >afm-LBS-Redaktion@mammillaria.eu
        </a>
      </P>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Loseblatt",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
