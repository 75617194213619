<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1 v-if="sprachID === 0">Sonderhefte</h1>
        <h1 v-if="sprachID === 1">Special issues</h1>
      </div>
    </div>

    <div class="div stickyShop">
      <ul class="noDeko">
        <li class="borderRund p-3" @click="openBestellung()">
          <h2 v-if="sprachID === 0" class="bold">Bestellformular</h2>
          <h2 v-if="sprachID === 0" class="bold">Hier</h2>
          <h2 v-if="sprachID === 1" class="bold">Order form</h2>
          <h2 v-if="sprachID === 1" class="bold">Here</h2>
        </li>
      </ul>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-md-5 text-center">
        <img
          src="../assets/Sonder2022.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
      </div>
      <div class="col-11 col-md-5 m-0 pt-5">
        <div>
          <h3 v-if="sprachID === 0">Sonderheft 2022</h3>
          <h3 v-if="sprachID === 1">Special issue 2022</h3>
          <br />
          <h4>Holger Rudzinski</h4>
          <h4>Emil Heese, James Alberto McDowell und Mammillaria stella-de-tacubaya</h4>
          <br />

          <p v-if="sprachID === 0">8,50 Euro zuzüglich Porto</p>        
          <p v-if="sprachID === 1">8,50 Euro plus postage</p>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-md-5 text-center">
        <img
          src="../assets/Sonder2021.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
      </div>
      <div class="col-11 col-md-5 m-0 pt-5">
        <div>
          <h3 v-if="sprachID === 0">Sonderheft 2021</h3>
          <h3 v-if="sprachID === 1">Special issue 2021</h3>
          <br />
          <h4>Holger Rudzinski</h4>
          <h4>Die Reihe Leucocephalae Teil 1</h4>
          <br />

          <p v-if="sprachID === 0">29.- Euro zuzüglich Porto <br/>Inland 5,00 € Ausland 14,00 €</p>        
          <p v-if="sprachID === 1">29.- Euro plus postage  <br/>Germany 5,00 € World 14,00 €</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-md-5 text-center">
        <img
          src="../assets/Sonder2019.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
      </div>
      <div class="col-11 col-md-5 m-0 pt-5">
        <div>
          <h3 v-if="sprachID === 0">Sonderheft 2019</h3>
          <h3 v-if="sprachID === 1">Special issue 2019</h3>
          <br />
          <h4>Georg Engelmann</h4>
          <h4>seine Mammillarien</h4>
          <br />

          <p v-if="sprachID === 0">15.- Euro zuzüglich Porto</p>        
          <p v-if="sprachID === 1">15.- Euro plus postage</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-md-5 text-center">
        <img
          src="../assets/Sonder2018.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
      </div>
      <div class="col-11 col-md-5 m-0 pt-5">
        <div>
          <h3 v-if="sprachID === 0">Sonderheft 2018</h3>
          <h3 v-if="sprachID === 1">Special issue 2018</h3>
          <br />
          <h4>Die Sierra Mixteca Alta als</h4>
          <h4>Lebensraum im Wandel der Zeit</h4>
          <br />

          <p v-if="sprachID === 0">15.- Euro zuzüglich Porto</p>          
          <p v-if="sprachID === 1">15.- Euro plus postage</p>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-md-5 text-center">
        <img
          src="../assets/Sonder2017.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
      </div>
      <div class="col-11 col-md-5 m-0 pt-5">
        <div>
          <h3 v-if="sprachID === 0">Sonderheft 2017</h3>
          <h3 v-if="sprachID === 1">Special issue 2017</h3>
          <br />
          <h4>Das Erbe Ehrenbergs</h4>
          
          <br />

          <p v-if="sprachID === 0">15.- Euro zuzüglich Porto</p>          
          <p v-if="sprachID === 1">15.- Euro plus postage</p>
        </div>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-6 m-0 pt-5">
        <p v-if="sprachID === 0">          
          <br />Porto: <br />Innland 1,60 Euro <br />EU-Ausland 3,80 Euro
          <br /><br />

          Bestellung durch Einzahlung auf das Konto des AfM mit Angabe der
          vollständigen Anschrift.
          <br /><br />
          Sparkasse Westerwald-Sieg<br />
          IBAN: DE24 5735 1030 0050 0600 86<br />
          BIC: MALADE51AKI<br />
          <br />
        </p>
        <p v-if="languageID === 1">
            <br />Postage: <br />Inland 1.60 euros <br />EU countries 3.80 euros
            <br /><br />
           Order by paying into the account of the AfM stating the
           full address.
           <br /><br />
           Sparkasse Westerwald-Sieg<br />
           IBAN: DE24 5735 1030 0050 0600 86<br />
           BIC: MALADE51AKI<br />
           <br />
         </p>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-8 m-0 pt-5"></div>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Sonderhefte",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
