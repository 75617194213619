<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1 v-if="sprachID === 0" >Anzeigenannahme</h1>
        <h1 v-if="sprachID === 1" >ad acceptance</h1>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-12 col-md-8 m-0 pt-5 pb-5">
        <table class="table table-hover" border="1" width="100%" align="center">
          <tr align="center" >
            <th width="100px" v-if="sprachID === 0" >Anzeigenpreise:</th>
            <th width="100px" v-if="sprachID === 0" >1 Heft</th>
            <th width="100px" v-if="sprachID === 0" >2 Hefte</th>
            <th width="100px" v-if="sprachID === 0" >3 Hefte</th>
            <th width="100px" v-if="sprachID === 0" >4 Hefte(1Jahr)</th>
            <th width="100px" v-if="sprachID === 1" >ad prices:</th>
            <th width="100px" v-if="sprachID === 1" >1 book</th>
            <th width="100px" v-if="sprachID === 1" >2 book</th>
            <th width="100px" v-if="sprachID === 1" >3 book</th>
            <th width="100px" v-if="sprachID === 1" >4 book(1Year)</th>
          </tr>
          <tr align="center">
            <th>1/1 Seite</th>
            <td class="fw0">30,00</td>
            <td class="fw0">54,00</td>
            <td class="fw0">78,00</td>
            <td class="fw0">102,00</td>
          </tr>
          <tr align="center">
            <th>1/2 Seite</th>
            <td class="fw0">24,00</td>
            <td class="fw0">47,00</td>
            <td class="fw0">68,00</td>
            <td class="fw0">89,00</td>
          </tr>
          <tr align="center">
            <th>1/4 Seite</th>
            <td class="fw0">15,00</td>
            <td class="fw0">26,00</td>
            <td class="fw0">37,00</td>
            <td class="fw0">48,00</td>
          </tr>
          <tr align="center">
            <th>1/8 Seite</th>
            <td class="fw0">13,00</td>
            <td class="fw0">23,00</td>
            <td class="fw0">33,00</td>
            <td class="fw0">41,00</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 pt-5 pb-5">
      <p  v-if="sprachID === 0">
        Anfragen zu Anzeigen richten Sie bitte an:
      </p>
      <p  v-if="sprachID === 1">
        Please direct inquiries about advertisements to:
      </p>
      <ul class="noDeko">
        <li><span class="bold">Othmar Appenzeller</span></li>
        <li>Erikastraße 9</li>
        <li>D 66424 Homburg/ Saar</li>
        <li>Tel.: +49 (0)6841-2896</li>
        <li>E-Mail: <a href="mailto:afm-redaktion-mtbl@mammillaria.eu">afm-redaktion-mtbl@mammillaria.eu</a></li>
    </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Loseblatt",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
