<template>
  <div>
    <main>
    <TheNavbar />
      <div class="container-fluid p-0">
        <div class="row p-0 m-0">
          <div class="col-12 p-0 m-0">
            <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              mode="out-in"
              appear
              :key="$route.path"
              ><slot v-if="true"></slot></transition
            >
          </div>
        </div>
      </div>

    <TheFooter/>
    </main>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";
import TheFooter from "@/components/TheFooter";

export default {
  name: "Layout",
  components: {
    TheNavbar,
    TheFooter,
  },
};
</script>

<style scoped>
</style>