<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1 v-if="sprachID === 0">Samenfond</h1>
        <h1 v-if="sprachID === 1">Seed stock</h1>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 pt-5 flex-column align-items-center">
        <p v-if="sprachID === 0">
          Die neue Samenliste wird ab 2016 nicht mehr mit dem Mtbl. verschickt.
          <br />
          Sie kann auf der Homepage eingesehen werden. <br />
          Es besteht auch die Möglichkeit diese bei Herrn Andreas Berthold anzufordern.<br />
          <br />
          Andreas F. Berthold, Vogelsang 10, D-06184 Gottenz,<br />

          0157-39609407<br />
          - <a href="mailto:afm-Samenfond@mammillaria.eu">afm-Samenfond@mammillaria.eu</a
          ><br /><br />
          Der zu zahlende Betrag und die Kontoangaben liegen der Sendung bei. Von
          Mitgliedern gespendetes Saatgut wird gesammelt, gereinigt und verpackt.<br />
          Diese Samen werden in einer Samenliste aufgeführt, <br />
          aus der jedes Mitglied zum Selbstkostenpreis bestellen kann.
          <br /><br />
          In den letzten Jahren enthielt die Samenliste durchschnittlich ca. 750
          Angebotspositionen.
        </p>
        <p v-if="sprachID === 1">
          From 2016, the new seed list will no longer be associated with the Mtbl. sent.
          <br />
          It can be viewed on the home page. <br />
          There is also the possibility of this with Mr. Andreas Berthold request.<br />
          <br />
          Andreas F. Berthold, Vogelsang 10, D-06184 Gottenz,<br />

          0157-39609407<br />
          -
          <a href="mailto:afm-Samenfond@mammillaria.eu">afm-Samenfond@mammillaria.eu</a>
          <br /><br />
          The amount to be paid and the account details are enclosed with the shipment.
          Seeds donated by members are collected, cleaned and packed.<br />
          These seeds will be listed in a seed list, <br />
          from which every member can order at cost price.
          <br /><br />
          In recent years, the seed list has averaged about 750 Quotation Items.
        </p>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-4 m-0 pt-5 flex-column align-items-center">
        <ul class="noDeko">
          <li class="borderRund rundKopf" @click="openPDF('Vuepdf/Samenliste2024.pdf')">
            <span v-if="sprachID === 0" class="bold">Samenliste (Stand 15/01/2024)</span>
            <span v-if="sprachID === 1" class="bold">Seed list (status 15/01/2024)</span>
          </li>
          <li class="borderRund rundKopf" @click="openPDF('Vuepdf/Samenliste2023.pdf')">
            <span v-if="sprachID === 0" class="bold">Samenliste (Stand 20/01/2023)</span>
            <span v-if="sprachID === 1" class="bold">Seed list (status 20/01/2023)</span>
          </li>
          <li class="borderRund rundKopf" @click="openPDF('Vuepdf/Samenliste2022.pdf')">
            <span v-if="sprachID === 0" class="bold">Samenliste (Stand 21/01/2022)</span>
            <span v-if="sprachID === 1" class="bold">Seed list (status 21/01/2022)</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/Samenliste2021.pdf')">
            <span v-if="sprachID === 0" class="bold">Samenliste (Stand 15/01/2021)</span>
            <span v-if="sprachID === 1" class="bold">Seed list (status 15/01/2021)</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/Samenliste2020.pdf')">
            <span v-if="sprachID === 0" class="bold">Samenliste (Stand 18/01/2020)</span>
            <span v-if="sprachID === 1" class="bold">Seed list (status 18/01/2020)</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/Samenliste2019.pdf')">
            <span v-if="sprachID === 0" class="bold">Samenliste (Stand 18/01/2019)</span>
            <span v-if="sprachID === 1" class="bold">Seed list (status 18/01/2019)</span>
          </li>

          <li class="borderRund" @click="openPDF('Vuepdf/Samenliste2018.pdf')">
            <span v-if="sprachID === 0" class="bold">Samenliste (Stand 15/01/2018)</span>
            <span v-if="sprachID === 1" class="bold">Seed list (status 15/01/2018)</span>
          </li>
          <li class="text-center">- - -</li>
          <li class="borderRund" @click="openPDF('Vuepdf/Feldkurzel.pdf')">
            <span v-if="sprachID === 0" class="bold">Feldlistenkürzel</span>
            <span v-if="sprachID === 1" class="bold">field list abbreviation</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/Samenlisteallgemein.pdf')">
            <span v-if="sprachID === 0" class="bold"
              >Liste der Bestellnummern und Standorte aller Samenlieferungen ab 2012</span
            >
            <span v-if="sprachID === 1" class="bold"
              >List of order numbers and locations of all seed shipments 2012</span
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-5 text-center">
        <h4>
          <p v-if="sprachID === 0">Leiter des Samenfonds:</p>
          <p v-if="sprachID === 1">Head of Seed Fund:</p>
        </h4>
        <P>
          <span class="bold">Andreas F. Berthold</span> <br />
          Vogelsang 10<br />
          D-06184 Gottenz<br />
          TEL.: +49-(0)157-39609407 <br />

          E-Mail:
          <a class="text-Schwarz" href="afm-Samenfond@mammillaria.eu"
            >afm-Samenfond@mammillaria.eu
          </a>
        </P>
      </div>
    </div>
    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-3 m-0 pt-5 text-center">
        <h4>
          <p v-if="sprachID === 0">Mitarbeiter:</p>
          <p v-if="sprachID === 1">Employee:</p>
        </h4>
        <ul class="noDeko">
          <li><span class="bold">Ehrenfried Drescher</span></li>
          <li>An der Mauer 125</li>
          <li>D-98663 Heldburg</li>
          <li>TEL.: +49-(0)36871-21284</li>
          <li>
            E-Mail:
            <a class="text-Schwarz" href="mailto:afm-Samenfond@mammillaria.eu"
              >afm-Samenfond@mammillaria.eu</a
            >
          </li>
        </ul>
      </div>

      <div class="col-11 col-md-3 m-0 pt-5 text-center">
        <h4>
          <p v-if="sprachID === 0">Mitarbeiter:</p>
          <p v-if="sprachID === 1">Employee:</p>
        </h4>
        <ul class="noDeko">
          <li><span class="bold">Wolfgang John</span></li>
          <li>Kastanienallee 2</li>
          <li>D-99610 Wundersleben</li>
          <li>TEL.: +49-(0)36376-53366</li>
          <li>
            E-Mail:<a class="text-Schwarz" href="mailto:afm-Samenfond@mammillaria.eu"
              >afm-Samenfond@mammillaria.eu</a
            >
          </li>
        </ul>
      </div>

      <div class="col-11 col-md-3 m-0 pt-5 text-center">
        <h4>
          <p v-if="sprachID === 0">Mitarbeiter:</p>
          <p v-if="sprachID === 1">Employee:</p>
        </h4>
        <ul class="noDeko">
          <li><span class="bold">Claus Schlimper</span></li>
          <li>Stralsunder Straße 49</li>
          <li>D 04357 Leipzig</li>
          <li>TEL.: +49-(0)341-6019798</li>
          <li>
            E-Mail:
            <a class="text-Schwarz" href="mailto:afm-Samenfond@mammillaria.eu"
              >afm-Samenfond@mammillaria.eu</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="div stickyShop">
      <ul class="noDeko">
        <li class="borderRund p-3" @click="openBestellung()">
          <h2 v-if="sprachID === 0" class="bold">Bestellformular</h2>
          <h2 v-if="sprachID === 0" class="bold">Hier</h2>
          <h2 v-if="sprachID === 1" class="bold">Order form</h2>
          <h2 v-if="sprachID === 1" class="bold">Here</h2>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Samenfond",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
