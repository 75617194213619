
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
          <div class="row bg-WeissTransparent justifyCenter">
          <div class="col-3 text-center mt-5 p-0">
           <h1 v-if="sprachID === 0">Vorstand des AfM</h1> 
           <h1 v-if="sprachID === 1">Board of Directors of the AFM</h1> 
          </div>
        </div>
        <div class="row bg-WeissTransparent pb-5 justifyCenter">
          <div class="col-md-3 m-3">
            <div class="row ">
              <div class="col-md bg-Weiss">
                <div class="bg-Weiss textweiss">
                  <div class="p-0  text-center">
                    <h3 class="text-Rot fw-bold m-2 m-md-0" v-if="sprachID === 0">Vorsitzender</h3>
                    <h3 class="text-Rot fw-bold m-2 m-md-0" v-if="sprachID === 1">chairman</h3>
                    <p class="text-Rot fw-bold pt-0 p-0 m-0">——————————</p>
                  </div>
                 <h4 class="pt-2 pt-md-5"> Uwe Lehmann</h4>
                  <p class="pt-2 ">                     
                    Alte Poststr.48<br />
                    D 01471 Radeburg<br />
                    Tel.: +49-(0)35208 - 4765<br />
                    <a  href="mailto:afm-vorsitzender@mammillaria.eu">E-Mail : afm-vorsitzender@mammillaria.eu</a>
                    <br />
                  </p>
                </div>
              </div>             
            </div>
          </div>

          <div class="col-md-3 m-3 ">
            <div class="row ">
              <div class="col-md bg-Weiss">
                <div class="bg-Weiss textweiss">
                  <div class="p-0  text-center">                    
                    <h3 class="text-Rot fw-bold m-2 m-md-0" v-if="sprachID === 0">Schriftführer / Geschäftsstelle</h3>
                    <h3 class="text-Rot fw-bold m-2 m-md-0" v-if="sprachID === 1">Secretary/office</h3>
                    <p class="text-Rot fw-bold pt-0 p-0 m-0">——————————</p>
                  </div>
                  <h4 class="pt-2 pt-md-5"> Dr. Manfred Bruse</h4>
                  <p class="pt-2 ">                    
                    Gebrüder-Grimm-Str. 32<br />
                    D 49565 Bramsche (Engter)<br />
                    Tel.: +49-(0)171 1283891<br />
                    <a  href="mailto:afm-geschaeftsstelle@mammillaria.eu"
                      >E-Mail : afm-geschaeftsstelle@mammillaria.eu</a>
                    <br />
                  </p>
                </div>
              </div>          
            </div>
          </div>

          <div class="col-md-3 m-3 ">
            <div class="row ">
              <div class="col-md botsch bg-Weiss">
                <div class="bg-Weiss textweiss">
                  <div class="p-0  text-center" >                    
                    <h3 class="text-Rot fw-bold m-2 m-md-0" v-if="sprachID === 0">Kassierer</h3>
                    <h3 class="text-Rot fw-bold m-2 m-md-0" v-if="sprachID === 1">cashier</h3>
                    <p class="text-Rot fw-bold pt-0 p-0 m-0">——————————</p>
                  </div>
                  
                     <h4 class="pt-2 pt-md-5">Joachim Chupik</h4>
                  <p class="pt-2 ">
                      Leipzigerstr. 25<br />
                    D 63607 Wächtersbach<br />
                    Tel: +49(0)6053-601465<br />
                    <a  href="mailto:afm-kassierer@mammillaria.eu"
            >E-Mail : afm-kassierer@mammillaria.eu</a>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        
       

        
      </div>
    </template>

<script>

export default {
  name: "Praxis",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
  },
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
