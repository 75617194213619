<template #default>
  <div class="container-fluid p-0 m-0 background1">
    <div class="row pt-2 mb-0 mt-0 bg-WeissTransparent justifyCenter">
      <div class="col-md-8 title mt-5 bg-Dunkelgrau" id="printMe" ref="printMe">
        <div class="ps-4">
          <h1 v-if="sprachID === 0" class="text-Rot-Schatten fw-bold mt-5">Aufnahmeantrag</h1>
          <h1 v-if="sprachID === 1" class="text-Rot-Schatten fw-bold mt-5">Application for Admission</h1>
        </div>
        <div v-if="sprachID === 0" class="ps-4">
          Hiermit stelle ich gemäß § 3, Abs. 1 der Satzung den Antrag, Mitglied
          im Arbeitskreis für Mammillarienfreunde zu werden:
        </div>
        <div v-if="sprachID === 1" class="ps-4">
          I hereby apply according to § 3, paragraph 1 of the statutes to become a member
           to become a member of the working group for friends of mammillaria:
        </div>
        <div class="container p-4">
          <div class="row mb-3">
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailVorname">Vorname*</label>
              <label v-if="sprachID === 1" class="form-label" for="emailVorname">First name*</label>
              <input
                class="form-control"
                type="text"
                name="vorname"
                placeholder="Kaktus"
                v-model="mail.vorname"
                v-bind:class="background.vorname"
                
              />
            </div>
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailNachname">Nachname*</label>
              <label v-if="sprachID === 1" class="form-label" for="emailNachname">Family name*</label>
              <input
                class="form-control"
                type="text"
                name="nachname"
                placeholder="Mammillaria"
                v-model="mail.nachname"
                v-bind:class="background.nachname"
                
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-10">
              <label v-if="sprachID === 0" class="form-label" for="emailStrasse">Strasse*</label>
              <label v-if="sprachID === 1" class="form-label" for="emailStrasse">Street*</label>
              <input
                class="form-control"
                type="text"
                name="strasse"
                placeholder="Blumentopf"
                v-model="mail.strasse"
                v-bind:class="background.strasse"
                
              />
            </div>
            <div class="col-md-2">
              <label v-if="sprachID === 0" class="form-label" for="emailNummer">Nummer*</label>
              <label v-if="sprachID === 1" class="form-label" for="emailNummer">Number*</label>
              <input
                class="form-control"
                type="text"
                name="nummer"
                placeholder="2a"
                v-model="mail.nummer"
                v-bind:class="background.nummer"
                
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-2">
              <label v-if="sprachID === 0" class="form-label" for="emailPLZ">PLZ*</label>
              <label v-if="sprachID === 1" class="form-label" for="emailPLZ">ZIP*</label>
              <input
                class="form-control"
                type="text"
                name="plz"
                placeholder="01234"
                v-model="mail.plz"
                v-bind:class="background.plz"
                
              />
            </div>
            <div class="col-md-10">
              <label v-if="sprachID === 0" class="form-label" for="emailOrt">Ort*</label>
              <label v-if="sprachID === 1" class="form-label" for="emailOrt">Town*</label>
              <input
                class="form-control"
                type="text"
                name="ort"
                placeholder="Gewächshaus"
                v-model="mail.ort"
                v-bind:class="background.ort"
                
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailStaat">Staat</label>
              <label v-if="sprachID === 1" class="form-label" for="emailStaat">State</label>
              <input
                class="form-control"
                type="text"
                name="staat"
                placeholder="Deutschland"
                v-model="mail.staat"
                v-bind:class="background.Staat"
              />
            </div>
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailLand">Land</label>
              <label v-if="sprachID === 1" class="form-label" for="emailLand">Country</label>
              <input
                class="form-control"
                type="text"
                name="land"
                placeholder="NRW"
                v-model="mail.land"
                v-bind:class="background.land"
              />
            </div>
          </div>

          <!-- Email address input -->

          <div class="row mb-3">
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailAddress"
                >Email Adresse*</label
              >
              <label v-if="sprachID === 1" class="form-label" for="emailAddress"
                >Email address*</label
              >
              <input
                class="form-control"
                type="text"
                name="email"
                placeholder="max@example.de"
                v-model="mail.email"
                v-bind:class="background.email"
                
              />
            </div>
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailAddress">Geburtsdatum</label>
              <label v-if="sprachID === 1" class="form-label" for="emailAddress">Date of birth</label>
              <input
                class="form-control"
                type="text"
                name="geburtsdatum"
                placeholder="01.01.1970"
                v-model="mail.geburtsdatum"
                v-bind:class="background.geburtsdatum"
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailAddress">Telefon</label>
              <label v-if="sprachID === 1" class="form-label" for="emailAddress">Phone</label>
              <input
                class="form-control"
                type="text"
                name="telefon"
                placeholder="03671 12 34 56"
                v-model="mail.telefon"
                v-bind:class="background.telefon"
              />
            </div>
            <div class="col-md-6">
              <label v-if="sprachID === 0" class="form-label" for="emailAddress">Mobil</label>
              <label v-if="sprachID === 0" class="form-label" for="emailAddress">Mobile</label>
              <input
                class="form-control"
                type="text"
                name="mobil"
                placeholder="03671 12 34 56"
                v-model="mail.mobil"
                v-bind:class="background.mobil"
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <div class="bg-WeissTransparent p-2">
                <label v-if="sprachID === 0" class="form-label" for="message"
                  >Art der Mitgliedschaft*</label
                >
                <label v-if="sprachID === 1" class="form-label" for="message"
                  >type of membership*</label
                >
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="Art"
                    id="Vollmitglied"
                    checked
                  />
                  <label v-if="sprachID === 0" class="form-check-label" for="Vollmitglied">
                    Vollmitglied (36.--€)
                  </label>
                  <label v-if="sprachID === 1" class="form-check-label" for="Vollmitglied">
                    full member (36.--€)
                  </label>
                </div>
              
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="Art"
                    id="Familie"
                  />
                  <label v-if="sprachID === 0" class="form-check-label" for="Familie">
                    Familienanschlussmitglied² (8.-- €)
                  </label>
                  <label v-if="sprachID === 1" class="form-check-label" for="Familie">
                    Family Connection Member² (8.-- €)
                  </label>
                </div>
                
                <p v-if="sprachID === 0" class="small fw-normal pt-4">
                  ² Die Familienanschlussmitgliedschaft beinhaltet nicht den
                  Bezug des Mitteilungsblattes.
                </p>
                <p v-if="sprachID === 1" class="small fw-normal pt-4">
                  ² Family connection membership does not include subscription to the newsletter.
                </p>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <div class="bg-WeissTransparent p-2">
                <label v-if="sprachID === 0" class="form-label" for="message">Jahresbeitrag:</label>
                <p v-if="sprachID === 0">
                  Ich verpflichte mich, den Jahresbeitrag bis spätestens 31.
                  Januar eines jeden Jahres, auf das Konto des AfM einzuzahlen,
                  oder die Einzugsermächtigung zu erteilen.
                </p>
                <label v-if="sprachID === 1" class="form-label" for="message">annual fee:</label>
                <p v-if="sprachID === 1">
                  I agree to pay the annual fee no later than December 31st.
                   January of each year to pay into the AfM account,
                   or to grant direct debit authorization.
                </p>
                <div class="row justifyCenter">
                  <div class="col-md-6">
                    <ul class="koUL noDeko">
                      <li
                        class="borderRund"
                        @click="openPDF('Vuepdf/Einzugsermaechtigung.pdf')"
                      >
                        <span v-if="sprachID === 0"> Einzugsermächtigung</span>
                        <span v-if="sprachID === 1"> direct debit authorization</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p v-if="sprachID === 0">Zahlungen richten Sie bitte an</p>
                <p v-if="sprachID === 1">Please send payments to</p>
                <p class="ps-5">
                  Arbeitskreis für Mammillarienfreunde e.V.<br />
                  Sparkasse Westerwald-Sieg<br />
                  IBAN: DE24 5735 1030 0050 0600 86<br />
                  BIC: MALADE51AKI<br />
                </p>
                <p v-if="sprachID === 0">Gläubiger-Identifikations Nummer: DE86 ZZZ0 0000 6753 84</p>
                <p v-if="sprachID === 0">
                  Nur für Mitglieder aus nicht EU-Länder:<br />
                  Zahlungen an den AfM sind über PayPal möglich an AFM-Kasse@afm-mammillaria.de
                </p>
                <p v-if="sprachID === 0">
                  Der Beitrag für das laufende Jahr sowie die einmalige
                  Bearbeitungsgebühr in Höhe von 5.- € für Neumitglieder wird
                  mit Zusendung der Aufnahmebestätigung fällig.
                </p>
                <p v-if="sprachID === 0">
                  Alle angegebenen Daten werden für die Zeit der Mitgliedschaft
                  im AfM im vereinsinternen Mitglieder-Programm gespeichert.
                </p>
                <p v-if="sprachID === 0">
                  Mit der Zusendung des Antrages gebe ich mein Einverständnis
                  zur ausschließlich vereinsinternen Datenspeicherung und
                  bestätige meinen Wunsch, Mitglied im AfM zu werden.
                </p>
                
                 <p v-if="sprachID === 1">Creditor Identification Number: DE86 ZZZ0 0000 6753 84</p>
                <p v-if="sprachID === 1">
                  Only for members from non-EU countries:<br />
                   Payments to the AfM are possible via PayPal to AFM-Kasse@afm-mammillaria.de
                </p>
                <p v-if="sprachID === 1">
                  The contribution for the current year as well as the one-time
                   Processing fee of 5.- € for new members
                   due upon receipt of the confirmation of admission.
                </p>
                <p v-if="sprachID === 1">
                 All data given are for the time of membership
                   stored in the AfM in the association's internal membership program.
                </p>
                <p v-if="sprachID === 1">
                 By submitting the application, I give my consent
                   for exclusively internal data storage and
                   confirm my desire to become a member of the AfM.
                </p>
                 
                <p></p>
              </div>
            </div>
          </div>
          <!-- Message input -->
        </div>
      </div>
      <div class="col-md-8 title mt-0  mb-5 bg-Dunkelgrau">
        <div class="container py-4">
          <!-- Form submissions success message -->
          <div class="d-none" id="submitSuccessMessage">
            <div class="text-center mb-3">Formular wurde gesendet!</div>
          </div>

          <!-- Form submissions error message -->
          <div class="d-none" id="submitErrorMessage">
            <div class="text-center text-danger mb-3">
              Fehler beim Senden des Formulars!<br />
              Sie können uns auch unter der Rufnummer: 035208-4765 erreichen
            </div>
          </div>
          <div class="col-md-10 mb-3">
            <p v-if="sprachID === 0">
              Sie können das Formular durch klicken auf "Senden" an uns
              übermitteln oder ausdrucken und unterschrieben zuschicken.
            </p>
            <P v-if="sprachID === 0">
              Per Post bitte an folgende Adresse:<br/>
              AfM e.V. <br/>Dr. Manfred Bruse<br/>          Gebrüder-Grimm-Str. 32<br/>          D-49565 Bramsche (Entger)
            </P>
            <p v-if="sprachID === 1">
              You can send the form to us by clicking "Send".
               send it to us or print it out and send it signed.
            </p>
            <P v-if="sprachID === 1">
              By post please to the following address:<br/>
              AfM e.V. <br/>Dr. Manfred Bruse<br/>          Gebrüder-Grimm-Str. 32<br/>          D-49565 Bramsche (Entger)
            </P>
          </div>
          <div class="col-md-10 mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="andere"
              v-model="mail.datenschutz"
              v-bind:class="background.datenschutz"
            />
            <label v-if="sprachID === 0" class="form-check-label" for="andere"
              >&nbsp; Ich habe die
              <a href="/Datenschutz">Datenschutzerklärung</a> zur Kenntnis
              genommen.
            </label>
             <label v-if="sprachID === 1" class="form-check-label" for="andere"
                  >&nbsp; I have the
                  <a href="/Datenschutz">Data protection</a> note taken.
                </label>
            <label class="form-check-label inputerror"> </label>
          </div>

          <!-- Form submit button -->
          <div class="d-grid">
            <button
              class="btn bg-Rot text-Weiss btn-lg"
              @click="sendMail()"
              type="submit"
            >
              Senden
            </button>
          </div>
          <div class="d-grid">
            <button
              type="button"
              class="btn btn-warning"
              id="ArtikelEtikettDruckenBT"
              @click="print()"
            >
              Drucken
            </button>
          </div>
          <!--</form>-->
          <div class="col-md-12 mb-3">
            <div class="row">
              <div class="col-md-6 text-center"></div>
              <div class="col-md-6 text-end">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import axios from "axios";
import { Printd } from "printd";

const d = new Printd();

export default {
  name: "Kontakt",

  data() {
    return {
      newSite: null,
      mail: {
        vorname: "",
        nachname: "",
        strasse: "",
        nummer: "",
        plz: "",
        ort: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: false,
        gesendet: "",
      },
      background: {
        vorname: "",
        nachname: "",
        strasse: "",
        nummer: "",
        plz: "",
        ort: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: "",
      },
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    checkEingaben() {
      let re = true;

      if (this.mail.vorname == "") {
        this.background.vorname = "border border-2 border-danger";
        re = false;
        console.log("send vorname");
      } else {
        this.background.vorname = "border border-2 border-success";
      }

      if (this.mail.nachname == "") {
        this.background.nachname = "border border-2 border-danger";
        re = false;
        console.log("send nachname");
      } else {
        this.background.nachname = "border border-2 border-success";
      }

      if (this.mail.strasse == "") {
        this.background.strasse = "border border-2 border-danger";
        re = false;
        console.log("send strasse");
      } else {
        this.background.strasse = "border border-2 border-success";
      }

      if (this.mail.nummer == "") {
        this.background.nummer = "border border-2 border-danger";
        re = false;
        console.log("send nummer");
      } else {
        this.background.nummer = "border border-2 border-success";
      }

      if (this.mail.plz == "") {
        this.background.plz = "border border-2 border-danger";
        re = false;
        console.log("send plz");
      } else {
        this.background.plz = "border border-2 border-success";
      }

      if (this.mail.ort == "") {
        this.background.ort = "border border-2 border-danger";
        re = false;
        console.log("send ort");
      } else {
        this.background.ort = "border border-2 border-success";
      }

      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
        console.log("send email");
      } else {
        this.background.email = "border border-2 border-success";
      }
      // if (this.mail.message == "") {
      //   this.background.message = "border border-2 border-danger";
      //   re = false;
      //   console.log("send message");
      // } else {
      //   this.background.message = "border border-2 border-success";
      // }
      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
        console.log("send datenschutz");
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    sendMail() {
      
      console.log("checkEingaben");
      if (this.checkEingaben()) {
        console.log("checkEingaben ok");
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "afm-geschaeftsstelle@mammillaria.eu",
         //empfaenger: "joerg@pillar.de",
          Vorname: this.mail.vorname,
          Nachname: this.mail.nachname,
          Strasse: this.mail.strasse,
          Nummer: this.mail.nummer,
          PLZ: this.mail.plz,
          Ort: this.mail.ort,
          Email: this.mail.email,
          Telefon: this.mail.telefon,
          Grund: "Anmeldeformular Mitgliedschaft",
          Datenschutz: "Zustimmung erteilt",
        };
        let mail = this.mail;
        let background = this.background;
        console.log("send data");
        axios
          .post("https://pillar.de/afm/post_attachment.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.vorname = "";
            background.vorname = "";
            mail.nachname = "";
            background.nachname = "";
            mail.strasse = "";
            background.strasse = "";
            mail.nummer = "";
            background.nummer = "";
            mail.plz = "";
            background.plz = "";
            mail.ort = "";
            background.ort = "";
            mail.email = "";
            background.email = "";
            mail.telefon = "";
            background.telefon = "";
            mail.message = "";
            background.message = "";
            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
      else{
        console.log("checkEingaben error");
      }
    },
    print() {
      console.log("Hier wird gedruckt" + this.$el);
      d.print(this.$refs.printMe);
      // closeModal();
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped>
@media print {
  .noPrint {
    display: none;
    visibility: hidden;
  }
}
*:focus {
  outline: none;
}
body {
  font: 14px/21px "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode",
    sans-serif;
}

input:required,
textarea:required {
  background: #fff no-repeat 98% center;
}
::-webkit-validation-bubble-message {
  padding: 1em;
}
</style>
