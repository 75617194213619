<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter" id="t0">
          <div class="col-md t-center spacebetween">
            <div class="div borderRund rundKopf">
              <a href="#t1" class="text-Schwarz">
                <h5 v-if="sprachID === 0">REM - ARCHIV</h5>
                <h5 v-if="sprachID === 1">REM - ARCHIVE</h5>
              </a>
            </div>
          </div>
          <div class="col-md t-center spacebetween">
            <div class="div borderRund rundKopf">
              <a href="#t2" class="text-Schwarz">
                <h5 v-if="sprachID === 0">BIBLIOTHEK</h5>
                <h5 v-if="sprachID === 1">LIBRARY</h5>
              </a>
            </div>
          </div>
          <div class="col-md t-center spacebetween">
            <div class="div borderRund rundKopf">
              <a href="#t3" class="text-Schwarz">
                <h5 v-if="sprachID === 0">FELDLISTEN</h5>
                <h5 v-if="sprachID === 1">FIELD LISTS</h5>
              </a>
            </div>
          </div>
          <div class="col-md t-center spacebetween">
            <div class="div borderRund rundKopf">
              <a href="#t4" class="text-Schwarz">
                <h5 v-if="sprachID === 0">ERSTBESCHREIBUNGEN</h5>
                <h5 v-if="sprachID === 1">FIRST DESCRIPTIONS</h5>
              </a>
            </div>
          </div>
        </div>

        <a href="#t0" class="sticky">
          <i class="fas fa-angle-double-up" style="font-size: 36px"></i>
        </a>

        <div class="row justifyCenter bg-WeissTransparent" id="t1">
          <div class="col-md-10 t-center aussendiv">
            <div class="pt-5 pb-5">
              <span class="text-Rot-Schatten" v-if="sprachID === 0">REM - ARCHIV</span>
              <span class="text-Rot-Schatten" v-if="sprachID === 1">REM - ARCHIVE</span>
            </div>
          </div>
        </div>
        <div class="row t-center justifyCenter bg-WeissTransparent">
          <div class="col-md-8 pb-5">
            <p v-if="sprachID === 0">
              Das REM-Archiv enthält in der Regel 4 verschiedene Aufnahmen von z.Z. über
              700 Samenproben. <br />
              Entsprechend dem Bedarf für Artikel im Mtbl. und der LBS kommen weitere
              Aufnahmen hinzu. <br />
              Diese können bei Bedarf über den Leiter des REM-Archivs von allen
              Mitgliedern angefordert werden.
            </p>
            <p v-if="sprachID === 1">
              The REM archive usually contains 4 different recordings of currently over
              700 seed samples. <br />
              According to the need for items in Mtbl. and the LBS come more recordings
              added. <br />
              If necessary, these can be obtained from the head of the REM archive members
              are requested.
            </p>
          </div>
        </div>
        <div class="row t-center justifyCenter bg-WeissTransparent nurBildschirm">
          <div class="col-md-6 bg-Hellgrau roundedCorner p-5">
            <carouselRem></carouselRem>
          </div>
        </div>
        <div class="row bg-WeissTransparent nurHandy">
          <div class="col-12 bg-Hellgrau roundedCorner p-3">
            <carouselRemHandy></carouselRemHandy>
          </div>
        </div>
        <div class="row pt-5 t-center justifyCenter bg-WeissTransparent">
          <div class="col-md-8 pb-5 ">
            <p v-if="sprachID === 0">
              Das REM-Archiv wurde überarbeitet und ergänzt.<br /> Es enthält nur (Stand
              01.07.2024) die Aufnahmen von 783 Samenproben. <br /> Nähere Einzelheiten können
              Sie beim Leiter des REM-Archivs erfragen <br />unter
              afm-REM-archiv@mammillaria.eu.
            </p>
            <p v-if="sprachID === 1">
              The SEM archive has been revised and supplemented. <br />It only contains (as of
              July 1, 2024) images of 783 seed samples. <br />For further details, please
              contact the head of the SEM archive <br />at afm-REM-archiv@mammillaria.eu.
            </p>
          </div>
        </div>

        <div class="row t-center justifyCenter bg-WeissTransparent">
          <div class="col-md-2 p-5">
            <ul class="koUL noDeko">
              <li class="borderRund" @click="openPDFbreit('REMListe.pdf')">
                <span class="bold">REM - Archiv - Liste </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="row t-center justifyCenter pt-1 bg-WeissTransparent">
          <div class="col-md-6">
            <h4>
              <p v-if="sprachID === 0">Leiter des REM-Archivs</p>
              <p v-if="sprachID === 1">Head of the REM Archive</p>
            </h4>
            <P>
              <span class="bold">Rainer Pillar</span> <br />
              Klaus-Stürmer-Str.13<br />
              D 49478 Osnabrück<br />
              Tel.: +49(0)541-445759<br />

              E-Mail:
              <a class="text-Schwarz" href="mailto:afm-REM-Archiv@mammillaria.eu"
                >afm-REM-Archiv@mammillaria.eu</a
              >
            </P>
          </div>
        </div>

        <!-- Bibliothek-->

        <div class="row bg-Hellgrau justifyCenter" id="t2">
          <div class="col-md-10 t-center aussendiv text-Weiss">
            <div class="pt-5 pb-5 text-Weiss-Schatten">
              <h2 v-if="sprachID === 0">BIBLIOTHEK</h2>
              <h2 v-if="sprachID === 1">LIBRARY</h2>
            </div>
          </div>
        </div>
        <div class="row bg-Hellgrau t-center justifyCenter text-Weiss">
          <div class="col-md-8 pb-5">
            <p v-if="sprachID === 0">
              Das Bücherverzeichnis des AfM kann dann nur auf den internen Seiten des AfM
              eingesehen werden. <br />
              Außerdem wurde eine Liste der digital erhältlichen Veröffentlichungen
              erstellt, <br />
              die ebenfalls über den internen Bereich eingesehen werden kann. <br />
            </p>
            <p v-if="sprachID === 1">
              The book directory of the AfM can then only refer to the internal pages of
              the AfM can be viewed. <br />
              Also, we have a list of those available digitally Publications created,
              <br />
              which can also only be viewed via the internal area can. <br />
            </p>
          </div>
        </div>
        <div class="row t-center bg-Hellgrau justifyCenter nurBildschirm">
          <div class="col-md-6 roundedCorner p-5">
            <carouselBibliothek></carouselBibliothek>
          </div>
        </div>
        <div class="row t-center bg-Hellgrau justifyCenter nurHandy">
          <div class="col-md-6 roundedCorner p-3">
            <carouselBibliothekHandy></carouselBibliothekHandy>
          </div>
        </div>
        <div class="row t-center bg-Hellgrau justifyCenter text-Weiss pt-5">
          <div class="col-md-6">
            <h4>
              <p v-if="sprachID === 0">Leiter des Bibliothek</p>
              <p v-if="sprachID === 1">head of the library</p>
            </h4>
            <P>
              <span class="bold">Guido Knaup</span> <br />
              Bester Feld 12<br />
              D-48291 Telgte<br />
              Tel.: +49(0) 1711917244<br />
              E-Mail:
              <a class="text-Weiss" href="mailto:afm-bibliothek@mammillaria.eu"
                >afm-bibliothek@mammillaria.eu</a
              >
            </P>
          </div>
        </div>

        <!-- FELDLISTEN -->
        <div class="row justifyCenter bg-WeissTransparent" id="t3">
          <div class="col-md-10 t-center aussendiv">
            <div class="pt-5 pb-5 text-Rot-Schatten">
              <h1 v-if="sprachID === 0">FELDLISTEN</h1>
              <h1 v-if="sprachID === 1">FIELD LISTS</h1>
            </div>
          </div>
        </div>
        <div class="row t-center justifyCenter bg-WeissTransparent">
          <div class="col-md-8">
            <p v-if="sprachID === 0">
              In vielen Sammlungen sind Pflanzen mit Feldnummern vorhanden.
              <br />
              Um Ihnen das Finden der Standorte zu erleichtern, finden Sie hier einige
              Feldlisten. <br />
              Wenn Ihnen weitere Feldlisten bekannt sind, bitte schicken Sie uns diese.
              <br />
              <br />
              Wir veröffentlichen diese gerne.
              <br />
              <br />
            </p>
            <p v-if="sprachID === 1">
              Many collections contain plants with field numbers.
              <br />
              To make it easier for you to find the locations, go here some field lists.
              <br />
              If you know other field lists, please send us this. <br />
              <br />
              We are happy to publish them.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div class="row t-center justifyCenter bg-WeissTransparent">
          <div class="col-md-3 pb-5">
            <h4 v-if="sprachID === 0">aktuelle Feldlisten</h4>
            <h4 v-if="sprachID === 1">current field lists</h4>
            <ul class="koUL noDeko">
              <li class="borderRund" @click="openPDF('fieldlistlacoste.pdf')">
                <span class="bold">Michel Lacoste </span>
              </li>
              <li class="borderRund" @click="openPDF('fieldlistLau.pdf')">
                <span class="bold">Alfred B.Lau </span>
              </li>
              <li class="borderRund" @click="openPDF('fieldlistReppenhagen.pdf')">
                <span class="bold">Werner Reppenhagen </span>
              </li>
              <li class="borderRund" @click="openPDF('fieldlistRogo.pdf')">
                <span class="bold">Helmut Rogozinski </span>
              </li>
              <li class="borderRund" @click="openPDF('fieldlistWTH.pdf')">
                <span class="bold">Wolter ten Hoeve </span>
              </li>
              <li class="borderRund" @click="openPDF('TL_Mex.pdf')">
                <span class="bold">Thomas Linzen </span>
              </li>
            </ul>
            <h4 v-if="sprachID === 0">aktuelle Klassifikationen</h4>
            <h4 v-if="sprachID === 1">current classifications</h4>
            <ul class="koUL noDeko">
              <li class="borderRund" @click="openPDF('klasifikationenReppenhagen.pdf')">
                <span class="bold">Reppenhagen</span>
              </li>

              <li class="borderRund" @click="openPDF('klasifikationenLuethy.pdf')">
                <span class="bold">Lüthy</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row t-center justifyCenter pb-5 bg-WeissTransparent nurBildschirm">
          <div class="col-md-6 roundedCorner p-5">
            <carouselFeldlisten></carouselFeldlisten>
          </div>
        </div>
        <div class="row t-center justifyCenter pb-5 bg-WeissTransparent nurHandy">
          <div class="col-md-6 roundedCorner p-3">
            <carouselFeldlistenHandy></carouselFeldlistenHandy>
          </div>
        </div>

        <div class="row bg-Hellgrau justifyCenter" id="t4">
          <div class="col-md-10 t-center aussendiv text-Weiss">
            <div class="pt-5 pb-5 text-Weiss-Schatten">
              <h1 v-if="sprachID === 0">Erstbeschreibungen</h1>
              <h1 v-if="sprachID === 1">first descriptions</h1>
            </div>
          </div>
        </div>
        <div class="row bg-Hellgrau t-center justifyCenter text-Weiss">
          <div class="col-md-8 pb-5">
            <p v-if="sprachID === 0">
              Durch die Einrichtung werden alle erreichbaren Erstbeschreibungen über
              Mammillarien, <br />
              Coryphanthen, Escobarien und Neolloydien gesammelt und archiviert.
              <br />
              <br />
              Kopien der vorhandenen Erstbeschreibungen können von Mitgliedern beim
              Leiter<br />
              der Einrichtung gegen Erstattung der Selbstkosten angefordert werden.<br />
              <br />
              Sollten Sie Erstbeschreibungen besitzen, die hier nicht aufgeführt sind,
              <br />
              wären wir Ihnen für eine gute Kopie mit vollständiger Quellenangabe dankbar.
              <br />
            </p>
            <p v-if="sprachID === 1">
              By setting up all attainable initial descriptions about Mammillaria, <br />
              Coryphanths, Escobaria and Neolloydia collected and archived.
              <br />
              <br />
              Copies of the existing first descriptions can be obtained from members at
              the leader<br />
              requested by the facility against reimbursement of the cost price become.<br />
              <br />
              If you have first descriptions that are not listed here are,
              <br />
              we would be you for a good copy with complete Credit grateful.
              <br />
            </p>
          </div>
        </div>
        <div class="row t-center bg-Hellgrau justifyCenter text-Weiss">
          <div class="col-md-3 pb-5">
            <h4 v-if="sprachID === 0">aktuelle Erstbeschreibungen</h4>
            <h4 v-if="sprachID === 1">current first descriptions</h4>
            <ul class="koUL noDeko">
              <li class="borderRund" @click="openPDF('Erstbeschreibungen_2022.pdf')">
                <span class="bold text-Schwarz">Jahrgang 2022 </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row t-center bg-Hellgrau justifyCenter nurBildschirm">
          <div class="col-md-6 roundedCorner p-5">
            <carouselErstbeschreibungen></carouselErstbeschreibungen>
          </div>
        </div>
        <div class="row t-center bg-Hellgrau justifyCenter nurHandy">
          <div class="col-12 roundedCorner p-3">
            <carouselErstbeschreibungenHandy></carouselErstbeschreibungenHandy>
          </div>
        </div>
        <div class="row t-center bg-Hellgrau justifyCenter text-Weiss pb-5 pt-5">
          <div class="col-md-6">
            <h4>
              <p v-if="sprachID === 0">Leiter des Erstbeschreibungen</p>
              <p v-if="sprachID === 1">Head of First Descriptions</p>
            </h4>
            <P>
              <span class="bold">Erich Hieber</span> <br />
              Hölderlinstraße 29<br />
              D 71384 Weinstadt<br />
              Tel.: +49(0) 7151-67961
              <br />
              E-Mail:
              <a class="text-Weiss" href="mailto:afm-erstbeschreibungen@mammillaria.eu"
                >afm-erstbeschreibungen@mammillaria.eu
              </a>
            </P>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import carouselRem from "../components/carouselRem.vue";
import carouselBibliothek from "../components/carouselBibliothek.vue";
import carouselFeldlisten from "../components/carouselFeldlisten.vue";
import carouselErstbeschreibungen from "../components/carouselErstbeschreibungen.vue";

import carouselRemHandy from "../components/carouselRemHandy.vue";
import carouselBibliothekHandy from "../components/carouselBibliothekHandy.vue";
import carouselFeldlistenHandy from "../components/carouselFeldlistenHandy.vue";
import carouselErstbeschreibungenHandy from "../components/carouselErstbeschreibungenHandy.vue";

import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalPDFBreit from "../components/ModalPDFBreit.vue";
//import carouselErstbeschreibungenHandy from "../components/carouselErstbeschreibungenHandy.vue";

export default {
  name: "Medien",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
    carouselRem,
    carouselBibliothek,
    carouselFeldlisten,
    carouselErstbeschreibungen,
    carouselRemHandy,
    carouselBibliothekHandy,
    carouselFeldlistenHandy,
    carouselErstbeschreibungenHandy,
  },
  methods: {
    openPDFbreit(pdf) {
      const props = { pdf: "Vuepdf/" + pdf };
      openModal(modalPDFBreit, props);
    },
    openPDF(pdf) {
      const props = { pdf: "Vuepdf/" + pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
