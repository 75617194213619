<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 p-0  justifyCenter">
      <div class="col-10 m-0 p-0 text-Rot">
        <h4>2009</h4>
      </div>
    </div>

    <div class="row m-0 p-0  justifyCenter">
      <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p v-if="sprachID === 0">Ausgabe 1</p>
        <p v-if="sprachID === 1">output 1</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2009-1.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko">
          <li
            class="borderRund"
            @click="openPDF('IHV/IHVHeft12009.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          
        </ul>
      </div>
      <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p v-if="sprachID === 0">Ausgabe 2</p>
        <p v-if="sprachID === 1">output 2</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2009-2.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko">
          <li
            class="borderRund"
            @click="openPDF('IHV/IHVHeft22009.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          
        </ul>
      </div>
      <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p v-if="sprachID === 0">Ausgabe 3</p>
        <p v-if="sprachID === 1">output 3</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2009-3.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko">
          <li
            class="borderRund"
            @click="openPDF('IHV/IHVHeft32009.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          
        </ul>
      </div>
      <div class="col-6 col-md-3 m-0 p-0 text-center">
        <p v-if="sprachID === 0">Ausgabe 4</p>
        <p v-if="sprachID === 1">output 4</p>
        <img
          src="../../assets/Mitteilungsblatt/Titelbild/2009-4.jpg"
          class="Mitteilungsblattfoto zoom"
          alt="..."
        />
        <ul class="noDeko">
          <li
            class="borderRund"
            @click="openPDF('IHV/IHVHeft42009.pdf')"
          >
            <span v-if="sprachID === 0" class="bold">Inhaltsverzeichnis</span>
            <span v-if="sprachID === 1" class="bold">Table of Contents</span>
          </li>
          
        </ul>
      </div>
    </div>
    <div class="row m-0 p-0 justifyCenter">
      <div class="col-12 col-md-4 m-0 p-0 text-center">
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('IHV/IHV2009.pdf')">
                        <span v-if="sprachID === 0" class="bold">Jahresinhaltsverzeichnis</span>            
            <span v-if="sprachID === 1" class="bold">Annual table of contents</span>
          </li>          
        </ul>
      </div>
     
    </div>
  </div>
</template>
<script>

import { openModal } from "jenesius-vue-modal";
import modalPDF from "../../components/ModalPDF.vue";

export default {
  name: "J2009",
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
    computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
}



</script>