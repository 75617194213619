<template>
    <div class="row position-relative p-0 m-0">
      
      <div class="col-md-12 p-0 m-0">
        <ul
          class="nav header_Justify border-bottom border-danger border-2 fw-bold text-uppercase "
        >
          <li class="nav-item active">
            <router-link
              to="/"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/' ? 'nav-active' : 'nav-deactiv',
              ]"
              >Start</router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Verein"
              class="nav-link"
              :class="[
                activeScreen == '/Verein' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Verein' ? 'nav-active' : 'nav-deactiv',
              ]">Verein</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Verein' ? 'nav-active' : 'nav-deactiv',
              ]">The board of directors</span>
              </router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Medien"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Medien' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Medien' ? 'nav-active' : 'nav-deactiv',
              ]">Medien</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Medien' ? 'nav-active' : 'nav-deactiv',
              ]">Media</span>
              </router-link
            >
          </li>
          
          <li class="nav-item active">
            <router-link
              to="/Galerie"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Galerie' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Galerie' ? 'nav-active' : 'nav-deactiv',
              ]">Fotogalerie</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Galerie' ? 'nav-active' : 'nav-deactiv',
              ]">Photo gallery</span>
              </router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Redaktionen"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Redaktionen' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Redaktionen' ? 'nav-active' : 'nav-deactiv',
              ]">Redaktionen</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Redaktionen' ? 'nav-active' : 'nav-deactiv',
              ]">editorial offices</span>
              </router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Veranstaltungen"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Veranstaltungen' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Veranstaltungen' ? 'nav-active' : 'nav-deactiv',
              ]">Veranstaltungen</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Veranstaltungen' ? 'nav-active' : 'nav-deactiv',
              ]">Events</span>
              </router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Shop"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Shop' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Shop' ? 'nav-active' : 'nav-deactiv',
              ]">Shop</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Shop' ? 'nav-active' : 'nav-deactiv',
              ]">Shop</span>
              </router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Kontakt"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv',
              ]">Kontakt</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv',
              ]">Contact</span>
              </router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Sonstiges"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Sonstiges' ? 'nav-active' : 'nav-deactiv',
              ]"
              >
              <span v-if="sprachID === 0"
              :class="[
                activeScreen == '/Sonstiges' ? 'nav-active' : 'nav-deactiv',
              ]">Sonstiges</span>
              <span v-if="sprachID === 1"
              :class="[
                activeScreen == '/Sonstiges' ? 'nav-active' : 'nav-deactiv',
              ]">Other</span>
              </router-link
            >
          </li>
           <li class="nav-item active ps-5 ms-5" >
            <img
                  src="../assets/deutschland.png"
                  id="ArtikelBild"
                  class="img-fluid flag"
                  alt="Bild"
                  @click="changeSprache(0)"    
                  v-if="sprachID === 1"                      
                />
                 <img
                  src="../assets/vereinigte-staaten.png"
                  id="ArtikelBild"
                  class="img-fluid flag"
                  alt="Bild"
                  @click="changeSprache(1)"   
                  v-if="sprachID === 0"               
                />
          </li>
           <li class="nav-item active ps-5 ms-5" >
           
                
          </li>
        </ul>
      </div>
     
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
    sprachID() {
      let sprachID =
        this.$store.getters.getSprachID;
      return sprachID;
    },
  },
  methods: {
    changeSprache(sprachwert){      
      const store = this.$store;
       store.dispatch("setSprachID", sprachwert)
          .then(() => {});
    }
  },
  data() {
    return {
      
    };
  },
  created() {},
};
</script>

<style scoped></style>
