<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter" id="t0">
          <div class="col-md-2 t-center spacebetween text-uppercase">
            <div
              class=" borderRund rundKopf"
              @click="vereinanzeige = 'vorstand'"
            >
              <span v-if="sprachID === 0">Vorstand</span>
              <span v-if="sprachID === 1">Board</span>
            </div>
          </div> 

          <div class="col-md-2 t-center spacebetween text-uppercase">
            <div
              class=" borderRund rundKopf"
              @click="vereinanzeige = 'satzung'"
            >
              <span v-if="sprachID === 0">Satzung</span>
              <span v-if="sprachID === 1">statute</span>
            </div>
          </div> 

          <div class="col-md-2 t-center spacebetween text-uppercase">
            <div
            class=" borderRund rundKopf"
            @click="vereinanzeige = 'mitglied'"
          >
            <span v-if="sprachID === 0">Wie werde ich Mitglied</span>
            <span v-if="sprachID === 1">How do I join</span>
          </div>
          </div> 
          

          <div class="col-md-2 t-center spacebetween text-uppercase">
            <div
              class=" borderRund rundKopf"
              @click="vereinanzeige = 'befreundet'"
            >
              <span v-if="sprachID === 0">Befreundete Seiten</span>
              <span v-if="sprachID === 1">Friendly Pages</span>
            </div>
          </div> 

          <div class="col-md-2 t-center spacebetween text-uppercase">
            <div
              class=" borderRund rundKopf"
              @click="vereinanzeige = 'wowas'"
            >
              <span v-if="sprachID === 0">Wo finde ich was</span>
              <span v-if="sprachID === 1">Where do I find what</span>
            </div>
          </div>

          <div class="col-md-2 t-center spacebetween text-uppercase">
            <div
              class=" borderRund rundKopf"
              @click="vereinanzeige = 'aktuell'"
            >
              <span v-if="sprachID === 0">Änderungshistorie</span>
              <span v-if="sprachID === 1">change history</span>
            </div>
          </div> 

          <div class="col-md-10 t-center spacebetween text-uppercase">
            
            
           

            
            

            
          </div>
        </div>
        <div class="row">
          <keep-alive>
            <component :is="vereinanzeige" />
          </keep-alive>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

import satzung from "./Satzung.vue";
import vorstand from "./Vorstand.vue";
import mitglied from "./Mitglied.vue";
import befreundet from "./Befreundet.vue";
import wowas from "./WoWas.vue";
import aktuell from "./Aktuelles.vue";

export default {
  name: "Redaktionen",

  data() {
    return {
      newSite: null,
      vereinanzeige: vorstand,
    };
  },

  components: {
    Layout,
    vorstand,
    satzung,
    mitglied,
    befreundet,
    wowas,
    aktuell,
  },
  methods: {
    redationsComponents() {
      if (!this.vereinanzeige) {
        // console.log("artikelAnzeige: Null");
        return vorstand;
      } else if (this.vereinanzeige && this.vereinanzeige === "vorstand") {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return vorstand;
      } else if (this.vereinanzeige && this.vereinanzeige === "satzung") {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return satzung;
      } else if (this.vereinanzeige && this.vereinanzeige === "mitglied") {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return mitglied;
      } else if (this.vereinanzeige && this.vereinanzeige === "befreundet") {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return befreundet;
      } else if (this.vereinanzeige && this.vereinanzeige === "wowas") {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return wowas;
      } else if (this.vereinanzeige && this.vereinanzeige === "aktuell") {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return aktuell;
      } 
      
      else {
        // console.log("artikelAnzeige: " + this.artikelAnzeige);
        return vorstand;
      }
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
