<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter" id="t0">
          <div class="col-md-4 t-center spacebetween">
            <div class="div borderRund rundKopf">
              <a href="#t0">
                <h5 v-if="sprachID === 0">Jahreshauptversammlung</h5>
                <h5 v-if="sprachID === 1">annual general meeting</h5>
              </a>
            </div>
            <div class="div borderRund rundKopf">
              <a href="#t1">
                <h5 v-if="sprachID === 0">Herbsttagung</h5>
                <h5 v-if="sprachID === 1">autumn conference</h5>
              </a>
            </div>
          </div>
        </div>
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter">
          <div class="col-md-8 t-center text-Weiss" v-if="sprachID === 0">
            Jährlich finden zwei Tagungen statt, im Frühjahr und im Herbst.
            <br />
            Die Frühjahrstagung ist gleichzeitig auch Jahreshauptversammlung der
            Mitglieder des AfM.
          </div>
          <div class="col-md-8 t-center text-Weiss" v-if="sprachID === 1">
            Two conferences are held each year, in spring and autumn. <br />
            The spring conference is also the annual general meeting of the Members of the
            AfM.
          </div>
        </div>

        <div class="row justifyCenter bg-WeissTransparent" id="t0">
          <div class="col-md-10 t-center aussendiv text-Rot-Schatten">
            <div class="pt-5 pb-5">
              <span class="text-Rot-Schatten" v-if="sprachID === 0"
                >Jahreshauptversammlung</span
              >
              <span class="text-Rot-Schatten" v-if="sprachID === 1"
                >annual general meeting</span
              >
            </div>
          </div>
        </div>

        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-md-6 text-center">Die JHV 2025 findet am 02.-04.05.2025 wieder in Nümbrecht/Bierenbachtal statt. <br /> Nähere Einzelheiten voraussichtlich in Mtbl. 4/2024</div>
        </div>

        <div class="row justifyCenter bg-WeissTransparent pt-5" id="t1">
          <div class="col-md-10 t-center aussendiv text-Rot-Schatten pb-5">
            <h1 v-if="sprachID === 0">Herbsttagung</h1>
            <h1 v-if="sprachID === 1">autumn conference</h1>
          </div>
        </div>
        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-md-4 t-center aussendiv">
            <ul class="noDeko p-0">
              <li class="borderRund p-2"
              @click="openPDF('PDF/Herbsttagung_AƒM_2024.pdf')">
                <span class="bold p-3" v-if="sprachID === 0"
                  >Die Herbsttagung findet vom 11. – 13.10.2024 <br /> in Aufenau statt</span
                >
                <span class="bold p-2" v-if="sprachID === 1"
                  >The autumn conference will take place from October 11th to 13th, 2024
                  in Aufenau</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-md-6 text-center" v-if="sprachID === 0">
            Liebe AƒM-ler,<br />
            zur Herbsttagung 2024 des Arbeitskreises für Mammillarienfreunde e. V. möchte
            ich Sie herzlich in den „Landgasthof zur Quelle“ in Wächtersbach/Aufenau
            einladen, wo schon die Frühjahrstagungen mit JHV 2018 und 2022 stattgefunden
            haben.
          </div>
          <div class="col-md-6 text-center" v-if="sprachID === 1">
            Dear AƒM members,<br />
            I would like to cordially invite you to the 2024 autumn meeting of the Working
            Group for Mammillaria Friends e. V. to the “Landgasthof zur Quelle” in
            Wächtersbach/Aufenau, where the spring meetings with AGM 2018 and 2022 have
            already taken place.
          </div>
        </div>
        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-6">
            <ul class="noDeko p-0">
              <li
                class="borderRund"
               @click="openPDF('PDF/Herbsttagung_AƒM_2024.pdf')"
              >
                <span v-if="sprachID === 0" class="bold">Einladungsschreiben</span>
                <span v-if="sprachID === 1" class="bold">Invitation letter</span>
              </li>
              
            </ul>
          </div>
        </div>
        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          
          <div class="col-6 px-2 text-center"> 
            <img
            src="../assets/Herbsttagung_AƒM_2024-2.png"
            class=" px-2"
            style="height: 200px;"
            alt="..."
          /> 
            <img
            src="../assets/Herbsttagung_AƒM_2024-1.png"
            class=" px-2"
            style="height: 200px;"
            alt="..."
          /></div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";

export default {
  name: "Praxis",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
