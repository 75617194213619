<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 m-0 p-0 text-Rot-Schatten text-center">
        <h1 v-if="sprachID === 0">Verein interner Bereich</h1>
        <h1 v-if="sprachID === 1">Association internal area</h1>
      </div>
    </div>

    <div
      class="row m-0 p-0 bg-WeissTransparent justifyCenter"
      v-if="vergessen.visible === false"
    >
      <div class="col-md-4 m-0 pt-5">
        <p>
          <label class="form-label" v-if="sprachID === 0">Benutzer</label>
          <label class="form-label" v-if="sprachID === 1">User</label>
          <input
            class="form-control"
            type="text"
            name="benutzer"
            placeholder="Benutzername"
            v-model="anmeldung.name"
            required
          />
        </p>
        <p>
          <label class="form-label" v-if="sprachID === 0">Passwort</label>
          <label class="form-label" v-if="sprachID === 1">Password</label>
          <input
            class="form-control"
            type="text"
            name="passwort"
            placeholder="Passwort"
            v-model="anmeldung.passwort"
            required
          />
        </p>
        <p>
          <button
            class="btn bg-Rot text-Weiss btn-lg"
            @click="sendUserdata()"
            type="submit"
          >
            <span v-if="sprachID === 0"> anmelden</span>
            <span v-if="sprachID === 1"> Sign in</span>
          </button>
          
         {{user.error}} 
        </p>
      </div>
    </div>
    <div
      class="row m-0 p-0 bg-WeissTransparent justifyCenter"
      v-if="vergessen.visible === false"
    >
      <div class="col-md-4 m-0">
        <ul class="noDeko">
          <li class="borderRund p-1" @click="vergessenAn()">
            <span class="bold" v-if="sprachID === 0">Passwort vergessen?</span>
            <span class="bold" v-if="sprachID === 1">Forgot Password?</span>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="row m-0 p-0 bg-WeissTransparent justifyCenter"
      v-if="vergessen.visible === false"
    >
      <div class="col-md-4 m-0">
        <ul class="noDeko">
          <li class="borderRund p-1" @click="registrieren()">
            <span class="bold" v-if="sprachID === 0">neu registrieren</span>
            <span class="bold" v-if="sprachID === 1">register</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="row m-0 p-0 bg-WeissTransparent justifyCenter"
    v-if="vergessen.visible === true"
  >
    <div class="col-md-4 m-0 pt-5">
      <h2 v-if="sprachID === 0">Passwort vergessen?</h2>
      <h2 v-if="sprachID === 1">Forgot Password?</h2>
      <p>
        <label class="form-label" v-if="sprachID === 0"
          >Bitte geben Sie Ihre Emailadresse ein.</label
        >
        <label class="form-label" v-if="sprachID === 1">E-mail address</label>
        <input
          class="form-control"
          type="text"
          name="email"
          placeholder="Emailadresse"
          v-model="vergessen.email"
          required
        />
      </p>
      <p>
        <button
          class="btn bg-Rot text-Weiss btn-lg"
          @click="sendPasswort()"
          type="submit"
        >
          <span v-if="sprachID === 0"> Passwort zurücksetzen</span>
          <span v-if="sprachID === 1"> resend password</span>
        </button>
      </p>
      <p v-if="sprachID === 0">
        Sie erhalten an die angegebene Adresse eine Mail mit weiteren
        Informationen
      </p>
      <p v-if="sprachID === 1">
        You will receive an email to the address provided with the password
        information.
      </p>
      <ul class="noDeko">
        <li class="borderRund p-1" @click="vergessenAus()">
          <span class="bold" v-if="sprachID === 0">anmelden</span>
          <span class="bold" v-if="sprachID === 1">Sign in</span>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="row m-0 p-0 bg-WeissTransparent justifyCenter"
    v-if="neu.visible === true"
  >
    <div class="col-md-4 m-0 pt-5">
      <p>
        <label class="form-label" v-if="sprachID === 0">Name</label>
        <label class="form-label" v-if="sprachID === 1">Name</label>
        <input
          class="form-control"
          type="text"
          name="Name"
          placeholder="Name"
          v-model="neu.name"
          v-bind:class="neuBorder.pw1"
        />
      </p>
      <p>
        <label class="form-label" v-if="sprachID === 0">Emailadresse</label>
        <label class="form-label" v-if="sprachID === 1">E-mail address</label>
        <input
          class="form-control"
          type="text"
          name="email"
          placeholder="Emailadresse"
          v-model="neu.email"
          v-bind:class="neuBorder.email"
          required
        />
      </p>
      <p></p>
      <span v-if="sprachID === 0"
        >Als Mitglied des AfM beantrage ich den Zugang zum internen Bereich.
        Nach der Freischaltung erhalten Sie eine Email mit weiteren
        Informationen.
      </span>
      <span v-if="sprachID === 1">
        As a member of the AfM, I apply for access to the internal area. After
        activation you will receive an email with more Information.</span
      >

      <p>
        <button
          class="btn bg-Rot text-Weiss btn-lg"
          @click="newRegistration()"
          type="submit"
        >
          <span v-if="sprachID === 0"> Registrierung beantragen</span>
          <span v-if="sprachID === 1"> apply for registration</span>
        </button>
      </p>
    </div>
  </div>
  <div
    class="row m-0 p-0 bg-WeissTransparent justifyCenter"
    style="height: 300px"
    v-if="neu.visible === false"
  ></div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";
import axios from "axios";

export default {
  name: "Loseblatt",

  data() {
    return {
      newSite: null,
      anmeldung: {
        name: "",
        passwort: "",
        error:"",
      },
      neu: {
        visible: false,
        email: "",
        name: "",
        
      },
      neuBorder: {
        email: "",
        pw1: "",
        pw2: "",
      },
      vergessen: {
        visible: false,
        email: "",
      },
    };
  },
  components: {},
  methods: {
   async sendUserdata() {
      //console.log(this.anmeldung);
      let payload = { globalparam: this.globalParam, userdata: this.anmeldung };

     await this.$store.dispatch("setUSER", payload).then()
      {
        console.log(this.user.error);
        if(this.user.error=="")
        {
          this.$emit("sonstigesAnzeige", "protokolle");
        }
        else
        {
          this.anmeldung.error =this.user.error;
        }
      }
    },

    registrieren() {
      this.neu.visible = true;
    },
    vergessenAn() {
      this.vergessen.visible = true;
    },
    vergessenAus() {
      this.vergessen.visible = false;
    },
    sendMail() {
      let fehler = 0;
      const neu = this.neu;
      if (neu.pw1 != neu.pw2 || neu.pw1 == "") {
        this.neuBorder.pw1 = "border border-2 border-danger";
        this.neuBorder.pw2 = "border border-2 border-danger";
        fehler++;
      } else {
        this.neuBorder.pw1 = "border border-2 border-success";
        this.neuBorder.pw2 = "border border-2 border-success";
      }

      if (neu.email == "") {
        this.neuBorder.email = "border border-2 border-danger";
        fehler++;
      } else {
        console.log(neu.email.includes("@"));
        if (neu.email.includes("@")) {
          this.neuBorder.email = "border border-2 border-success";
        } else {
          this.neuBorder.email = "border border-2 border-danger";
          fehler++;
        }
      }

      if (fehler == 0) {
        axios
          .post(
            this.globalParam.Global_ApiPfad + "checkuser.php",
            neu,
            this.globalParam.axiosConfig
          )
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    sendPasswort() {
      const payload2 = {
        key: "rthz03854vg065nzvg04gzmcf0568gvnm65",
        Usermail: this.vergessen.email,
      };
      axios
        .post(
          this.globalParam.Global_ApiPfad + "sendPasswort.php",
          payload2,
          this.globalParam.axiosConfig
        )
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
    newRegistration() {
      const payload2 = {
        key: "bI765r85F85(fc8V8598v97TVo/&F0/Gvt)",
        name: this.neu.name,
        mail: this.neu.email,
      };
      let neu = this.neu;
      axios
        .post(
          this.globalParam.Global_ApiPfad + "newRegistration.php",
          payload2,
          this.globalParam.axiosConfig
        )
        .then(function (response) {
          if (response.data == "Mail wurde gesendet!") {
            alert("Nachricht wurde gesendet.");
            neu.visible = false;
            neu.email = "";
            neu.name = "";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    }, 
    user() {
      let user = this.$store.getters.getUSER;      
      return user;
    },
  },
};
</script>

<style scoped></style>
