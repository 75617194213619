<template>
  <carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
    <slide v-for="slide in slides" :key="slide">
      <h3 class=" text-Weiss">{{slide}}</h3>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },


  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
     // console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
    "Guido Knaup ist neuer Leiter der Bibliothek",
      "Aufruf zur Mitarbeit bei Facebook",
      "JHV 2025 vom 02.-04.05.2025",
    ];
    return {
      slides,
    };
  },
};
</script>
