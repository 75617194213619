<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter p-0 m-0" id="t0">
          <div class="col-12 col-md-2">
            <div
              class="div borderRund rundKopf"
              @click="sonstigesAnzeige = 'anzeigenannahme'"
            >
              <span v-if="sprachID === 0">Anzeigenannahme </span>
              <span v-if="sprachID === 1">Delivery of all publications</span>
            </div></div>
          <div class="col-12 col-md-2"
              v-if="user.usertype == ''">
            <div
              class="div borderRund rundKopf"
              @click="sonstigesAnzeige = 'interna'"
            >
              <span v-if="sprachID === 0">Interna</span>
              <span v-if="sprachID === 1">internals</span>
            </div></div>
          <div class="col-12 col-md-2">
            <div
              class="div borderRund rundKopf"
              @click="sonstigesAnzeige = 'protokolle'"
              v-if="user.usertype != ''"
            >
              <span v-if="sprachID === 0">Protokolle</span>
              <span v-if="sprachID === 1">logs</span>
            </div></div>
          <div class="col-12 col-md-2">
            <div
              class="div borderRund rundKopf"
              @click="sonstigesAnzeige = 'downloads'"
              v-if="user.usertype != ''"
            >
              <span v-if="sprachID === 0">Downloads</span>
              <span v-if="sprachID === 1">Downloads</span>
            </div></div>
          <div class="col-12 col-md-2">
            <div
              class="div borderRund rundKopf"
              @click="sonstigesAnzeige = 'bibliothek'"
              v-if="user.usertype != ''"
            >
              <span v-if="sprachID === 0">Bibliothek</span>
              <span v-if="sprachID === 1">library</span>
            </div></div>
          <div class="col-12 col-md-2">
            <div
              class="div borderRund rundKopf"
              @click="sonstigesAnzeige = 'userverwaltung'"
              v-if="user.usertype == 'Admin'"
            >
              <span v-if="sprachID === 0">Userverwaltung</span>
              <span v-if="sprachID === 1">user administration</span>
            </div></div>
          <div class="col-12 col-md-2">
             <div
              class="div borderRund rundKopf"
              @click="logOut()"
              v-if="user.usertype != ''"
            >
              <span v-if="sprachID === 0">Abmelden</span>
              <span v-if="sprachID === 1">Log out</span>
            </div></div>
          
        </div>
        <div class="row">
          <keep-alive>
            <component :is="sonstigesAnzeige" @sonstigesAnzeige="sonstigesAnzeige=$event"/>
          </keep-alive>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import anzeigenannahme from "./Anzeigenannahme.vue";
import interna from "./Interna.vue";
import protokolle from "./Protokolle.vue";
import downloads from "./Downloads.vue";
import bibliothek from "./Bibliothek.vue";
import userverwaltung from "./Userverwaltung.vue";


export default {
  name: "Sonstiges",

  data() {
    return {
      newSite: null,
      sonstigesAnzeige: anzeigenannahme,
    };
  },
  components: {
    Layout,
    anzeigenannahme,
    interna,
    protokolle,
    downloads,
    bibliothek,
    userverwaltung,
  },
  methods: {
    redaktionsComponents() {
      if (!this.sonstigesAnzeige) {
        // console.log("artikelAnzeige: Null");
        return anzeigenannahme;
      } else if (
        this.sonstigesAnzeige &&
        this.sonstigesAnzeige === "anzeigenannahme"
      ) {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return anzeigenannahme;
      } else if (
        this.sonstigesAnzeige &&
        this.sonstigesAnzeige === "interna"       
      ) {        
        return interna;
      } else if (
        this.sonstigesAnzeige &&
        this.sonstigesAnzeige === "protokolle"
      ) {
        return protokolle;
      } else if (
        this.sonstigesAnzeige &&
        this.sonstigesAnzeige === "downloads"
      ) {
        return downloads;
      } else if (
        this.sonstigesAnzeige &&
        this.sonstigesAnzeige === "bibliothek"
      ) {
        return bibliothek;
      } else if (
        this.sonstigesAnzeige &&
        this.sonstigesAnzeige === "userverwaltung"
      ) {
        return userverwaltung;
      } else {
        return anzeigenannahme;
      }
    },
    logOut(){
      this.$store.dispatch("setUSERLOGOUT");
      this.sonstigesAnzeige = 'anzeigenannahme';
    }
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },    
    user() {
      let user = this.$store.getters.getUSER;      
      return user;
    },
  },
};
</script>

<style scoped></style>
