<template>
  <div
    class="card text-center text-vue modale"
  >
    <div class="card-body">
      <div class="container-fluid p-0 m-0 background1">
        <div class="row pt-2 pb-0 mt-0 bg-WeissTransparent justifyCenter">
          <div class="col-12 col-md-4md-10 title mt-2 borderRund rundKopf">
            <div class="row">
              <div class="ps-4">
                <h1
                  v-if="sprachID === 0"
                  class="text-Rot-Schatten fw-bold mt-2"
                >
                  Bilderupload für Facebook
                </h1>
                <h1
                  v-if="sprachID === 1"
                  class="text-Rot-Schatten fw-bold mt-5"
                >
                  Pictureupload for Facebook
                </h1>
              </div>
            </div>

            <div class="row">
              <div v-if="sprachID === 0" class="ps-4">
                <p>
                  Für unsere wöchentliche Post auf Facebook benötigen wir eine
                  größere Auswahl an Fotos.
                </p>
                <p>
                  Gesucht sind Aufnahmen mit einer Mindest-Auflösung von 72 dpi.
                </p>
                <p>
                  von den Gattungen: Coryphantha, Escobaria, Mammillaria und
                  Neolloydia
                </p>
              </div>
              <div v-if="sprachID === 1" class="ps-4">
                Do you have any questions or requests? The AfM team is gladly
                there for you!
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <div class="col">
                <p>
                  Motiv: Blühende oder fruchtende Pflanzen und Nah- bzw.
                  Detailaufnahmen der Bedornung.<br />
                  Aufnahmeort: Egal, ob am Standort oder in Kultur.<br />
                  Wichtig ist, dass die Bild-Dateien so beschriftet sind, wie
                  sie auch auf der Facebook-Seite erscheinen sollen, oder dass
                  anderweitig schriftlich beschrieben wird, wie sie zu
                  bezeichnen sind, <br /><br />z.B.: Mammillaria roczekii
                  Rog757, Canatlán, Dgo. Foto: H. Rogozinski
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-2 pb-5 mt-0 bg-WeissTransparent justifyCenter">
          <div class="col-12 col-md-10 mt-0 borderRund">
            <div class="row pt-4 pb-3 ">
              <div class="col-12 col-md-2 justifyLeft">
                <label v-if="sprachID === 0" class="form-label" for="name"
                  >Mein Name*</label
                >
                <label v-if="sprachID === 1" class="form-label" for="name"
                  >My Name*</label
                >
              </div>
              <div class="col-12 col-md-4 justifyLeft">
                <input
                  class="form-control"
                  type="text"
                  name="name"
                  v-bind:class="background.name"
                  placeholder="Mein Name"
                  v-model="mail.name"
                  required
                />
              </div>

              <div class="col-12 col-md-2 justifyLeft">
                <label
                  v-if="sprachID === 0"
                  class="form-label"
                  for="emailAddress"
                  >Email Adresse*</label
                >
                <label
                  v-if="sprachID === 1"
                  class="form-label"
                  for="emailAddress"
                  >E-mail address*</label
                >
              </div>
              <div class="col-12 col-md-4 justifyLeft">
                <input
                  class="form-control"
                  type="text"
                  name="email"
                  placeholder="max@example.de"
                  v-model="mail.email"
                  v-bind:class="background.email"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-7">
                <div class="input-group ">
                  <span class="input-group-text">Text Bild 1</span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mammillaria roczekii Rog757, Canatlán, Dgo. Foto: H. Rogozinski"
                    v-model="mail.text1"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5 justifyLeft">
                <input
                  type="file"
                  ref="bild1file"
                  name="bild1"
                  class="form-control "
                  id="bild1"
                  @change="uploadBild1"
                  v-if="mail.bild1 == ''"
                />
                <div class="justifyLeft" v-if="mail.bild1 != ''">{{mail.bild1}}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-7">
                <div class="input-group">
                  <span class="input-group-text">Text Bild 2</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="mail.text2"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5 justifyLeft">
                <input
                  type="file"
                  ref="bild2file"
                  name="bild2"
                  class="form-control"
                  id="bild2"
                  @change="uploadBild2" v-if="mail.bild2 == ''"
                />
                <p v-if="mail.bild2 != ''">{{mail.bild2}}</p>
                
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-7">
                <div class="input-group">
                  <span class="input-group-text">Text Bild 3</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="mail.text3"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5 justifyLeft">
                <input
                  type="file"
                  ref="bild3file"
                  name="bild3"
                  class="form-control"
                  id="bild3"
                  @change="uploadBild3" v-if="mail.bild3 == ''"
                />
                <p v-if="mail.bild3 != ''">{{mail.bild3}}</p>
                
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-7">
                <div class="input-group">
                  <span class="input-group-text">Text Bild 4</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="mail.text4"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5 justifyLeft">
                <input
                  type="file"
                  ref="bild4file"
                  name="bild4"
                  class="form-control"
                  id="bild4"
                  @change="uploadBild4" v-if="mail.bild4 == ''"
                />
                <p v-if="mail.bild4 != ''">{{mail.bild4}}</p>
                
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-7">
                <div class="input-group">
                  <span class="input-group-text">Text Bild 5</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="mail.text5"
                  />
                </div>
              </div>
              <div class="col-12 col-md-5 justifyLeft">
                <input
                  type="file"
                  ref="bild5file"
                  name="bild5"
                  class="form-control"
                  id="bild5"
                  @change="uploadBild5" v-if="mail.bild5 == ''"
                />
                <p v-if="mail.bild5 != ''">{{mail.bild5}}</p>
                
              </div>
            </div>
            <div class="container py-4">
              <!-- Form submissions success message -->
              <div class="d-none" id="submitSuccessMessage">
                <div v-if="sprachID === 0" class="text-center mb-3">
                  Formular wurde gesendet!
                </div>
                <div v-if="sprachID === 1" class="text-center mb-3">
                  Form has been sent!
                </div>
              </div>
              <div class="col-12 col-md-10 mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="andere"
                  v-model="mail.datenschutz"
                  v-bind:class="background.datenschutz"
                  required
                />
                <label
                  v-if="sprachID === 0"
                  class="form-check-label"
                  for="andere"
                  >&nbsp;*&nbsp; Ich habe die
                  <a href="/Datenschutz">Datenschutzerklärung</a> zur Kenntnis
                  genommen.
                </label>
                <label
                  v-if="sprachID === 1"
                  class="form-check-label"
                  for="andere"
                  >&nbsp;*&nbsp; I have the
                  <a href="/Datenschutz">Data protection</a> note taken.
                </label>
                <label class="form-check-label inputerror"> </label>
              </div>
              <div class="col-12 col-md-10 mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="andere"
                  v-model="mail.rechte"
                  v-bind:class="background.rechte"
                  required
                />
                <label
                  v-if="sprachID === 0"
                  class="form-check-label"
                  for="andere"
                  >&nbsp;*&nbsp; Ich habe alle Rechte in Bezug auf das geistige
                  Eigentum an den zur Verfügung gestellten Bildern
                </label>
                <label
                  v-if="sprachID === 1"
                  class="form-check-label"
                  for="andere"
                  >&nbsp;*&nbsp; I have all copyright for this pictures
                </label>
                <label class="form-check-label inputerror"> </label>
              </div>

              <!-- Form submit button -->
              <div class="d-grid">
                <button
                  class="btn bg-Rot text-Weiss btn-lg"
                  @click="sendMail()"
                  type="submit"
                >
                  Senden
                </button>
              </div>
              <!--</form>-->
            </div>
          </div>
        </div>
      </div>

      <button type="button" class="mt-3 btn btn-danger" @click="close()">
        Close
      </button>
    </div>
    <!-- Modal footer -->
  </div>
</template>

<script>
  const axiosConfig = {
    crossDomain: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

import { closeModal } from "jenesius-vue-modal";
import axios from "axios";

export default {
  name: "ModalsModalFacebook",

  data() {
    return {
      newSite: null,
      mail: {
        name: "",
        email: "",
        text1: "",
        bild1: "",
        bild1_temp: "",
        text2: "",
        bild2: "",
        bild2_temp: "",
        text3: "",
        bild3: "",
        bild3_temp: "",
        text4: "",
        bild4: "",
        bild4_temp: "",
        text5: "",
        bild5: "",
        bild5_temp: "",
        datenschutz: false,
        rechte: false,
        gesendet: "",
        empfaenger: "afm-geschaeftsstelle@mammillaria.eu",
      },
      background: {
        name: "",
        email: "",
        datenschutz: "",
        rechte: false,
      },
    };
  },

  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }
      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }
      if (this.mail.rechte == "") {
        this.background.rechte = "border border-2 border-danger";
        re = false;
      } else {
        this.background.rechte = "border border-2 border-success";
      }
      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    uploadBild1() {
      let mail = this.mail;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.bild1file.files.length; i++) {
        let file = this.$refs.bild1file.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post(
          "https://pillar.de/mailrelay/fileupload.php",
          formData,
          axiosConfig
        )
        .then(function (response) {
          console.log(response.data);
          mail.bild1 = response.data.file_org;
          mail.bild1_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadBild2() {
      let mail = this.mail;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.bild2file.files.length; i++) {
        let file = this.$refs.bild2file.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post(
          "https://pillar.de/mailrelay/fileupload.php",
          formData,
          axiosConfig
        )
        .then(function (response) {
          console.log(response.data);
          mail.bild2 = response.data.file_org;
          mail.bild2_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadBild3() {
      let mail = this.mail;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.bild3file.files.length; i++) {
        let file = this.$refs.bild3file.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post(
          "https://pillar.de/mailrelay/fileupload.php",
          formData,
          axiosConfig
        )
        .then(function (response) {
          console.log(response.data);
          mail.bild3 = response.data.file_org;
          mail.bild3_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadBild4() {
      let mail = this.mail;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.bild4file.files.length; i++) {
        let file = this.$refs.bild4file.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post(
          "https://pillar.de/mailrelay/fileupload.php",
          formData,
          axiosConfig
        )
        .then(function (response) {
          console.log(response.data);
          mail.bild4 = response.data.file_org;
          mail.bild4_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadBild5() {
      let mail = this.mail;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.bild5file.files.length; i++) {
        let file = this.$refs.bild5file.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post(
          "https://pillar.de/mailrelay/fileupload.php",
          formData,
          axiosConfig
        )
        .then(function (response) {
          console.log(response.data);
          mail.bild5 = response.data.file_org;
          mail.bild5_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    close() {
      closeModal();
    },
  },
  components: {},
  created() {},

  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>
