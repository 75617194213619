<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter p-0 m-0">
      <div class="col-12 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1>aktuelle Userliste</h1>
      </div>
    </div>
    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-12 col-md-8 m-0 pt-5">
        <div class="row pb-3">
          <div class="col-1">ID</div>
          <div class="col-md-3">Username</div>
          <div class="col-md-3">Mail</div>
          <div class="col-2">Type</div>
        </div>
        <div style="height:300px; overflow-y:auto; overflow-x:hidden; ">
        <div
          class="row"
          v-for="usersDetail in users"
          v-bind:key="usersDetail.id"
          v-bind:value="usersDetail.id"
        >
          <div class="col-1 col-md-1">{{ usersDetail.IDUser }}</div>
          <div class="col-11 col-md-3">{{ usersDetail.user }}</div>
          <div class="col-md-3">{{ usersDetail.mail }}</div>
          <div class="col-md-2">{{ usersDetail.typ }}</div>
          <div class="col-md-3 ">
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="editUser(usersDetail)"
            >
              edit
            </button>
            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="resetPW(usersDetail)"
            >
              resetPW
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="deleteUser(usersDetail)"
            >
              delete
            </button>
          </div>
        </div>
          
        </div>
      </div>
    </div>

    <div
      class="row m-0 pt-5 bg-WeissTransparent justifyCenter"
      v-if="edit.user != ''"
    >
      <div class="col-8 m-0 p-0 text-Rot-Schatten">
        <h1>edit User</h1>
      </div>
    </div>
    <div
      class="row m-0 p-0 bg-WeissTransparent justifyCenter"
      v-if="edit.user != ''"
    >
      <div class="col-md-8 m-0 pt-2">
        <div class="row">
          <div class="col-12 col-md-3">Username</div>
          <div class="col-md-4">Mail</div>
          <div class="col-md-2">Type</div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <input
              class="form-control"
              type="text"
              v-model="edit.user"
              v-bind:class="editBorder.user"
            />
          </div>
          <div class="col-md-4">
            <input
              class="form-control"
              type="text"
              v-model="edit.mail"
              v-bind:class="editBorder.mail"
            />
          </div>
          <div class="col-md-2">
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="edit.type"
              v-bind:class="editBorder.type"
            >
              <option v-bind:key="'User'" v-bind:value="'User'">User</option>
              <option v-bind:key="'Admin'" v-bind:value="'Admin'">Admin</option>
            </select>
          </div>
          <div class="col-2">
            <button type="button" class="btn btn-danger" @click="saveEdit()">
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row m-0 pt-5 bg-WeissTransparent justifyCenter"
      v-if="edit.user == ''"
    >
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1>neuen User anlegen</h1>
      </div>
    </div>

    <div
      class="row m-0 p-0 bg-WeissTransparent justifyCenter"
      v-if="edit.user == ''"
    >
      <div class="col-11 col-md-8 m-0 p3-5">
        <div class="row">
          <div class="col-11 col-md-3">Username</div>
          <div class="col-11 col-md-4">Mail</div>
          <div class="col-11 col-md-2">Type</div>
        </div>
        <div class="row">
          <div class="col-11 col-md-3">
            <input
              class="form-control"
              type="text"
              placeholder="user"
              v-model="neu.user"
              v-bind:class="neuBorder.user"
            />
          </div>
          <div class="col-11 col-md-4">
            <input
              class="form-control"
              type="text"
              placeholder="mail"
              v-model="neu.mail"
              v-bind:class="neuBorder.mail"
            />
          </div>
          <div class="col-11 col-md-2">
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="neu.type"
              v-bind:class="neuBorder.type"
            >
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </select>
          </div>
          <div class="col-2">
            <button type="button" class="btn btn-danger" @click="saveNew()">
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { openModal } from "jenesius-vue-modal";
// import modalPDF from "../components/ModalPDF.vue";

export default {
  name: "Loseblatt",

  data() {
    return {
      neu: {
        user: "",
        mail: "",
        type: "",
        error: 0,
      },
      neuBorder: {
        user: "",
        mail: "",
        type: "",
      },

      edit: {
        IDUser: "",
        user: "",
        mail: "",
        type: "",
        error: 0,
      },
      editBorder: {
        user: "",
        mail: "",
        type: "",
      },
      newSite: null,
    };
  },
  components: {},
  methods: {
    editUser(usersDetail) {
      console.log(usersDetail);
      this.edit.IDUser = usersDetail.IDUser;
      this.edit.user = usersDetail.user;
      this.edit.mail = usersDetail.mail;
      this.edit.type = usersDetail.typ;
    },
    resetPW(usersDetail) {
      let payload = {
        globalparam: this.globalParam,
        userdata: usersDetail,
      };
      this.$store.dispatch("resetPW", payload);
    },
    deleteUser(usersDetail) {
      let payload = {
        globalparam: this.globalParam,
        userdata: usersDetail,
      };
      this.$store.dispatch("deleteUser", payload);
    },
    saveNew() {
      this.neu.error = 0;
      if (this.neu.user == "") {
        this.neuBorder.user = "border border-2 border-danger";
        this.neu.error = 1;
      } else {
        this.neuBorder.user = "";
      }
      if (this.neu.mail == "") {
        this.neuBorder.mail = "border border-2 border-danger";
        this.neu.error = 1;
      } else {
        this.neuBorder.mail = "";
      }
      console.log(this.neu.type);
      if (this.neu.type == 0) {
        this.neuBorder.type = "border border-2 border-danger";
        this.neu.error = 1;
      } else {
        this.neuBorder.type = "";
      }

      if (this.neu.error == 0) {
        let payload = {
          globalparam: this.globalParam,
          userdata: this.neu,
        };
        this.$store.dispatch("addUSER", payload);

        this.neu.user = "";
        this.neu.mail = "";
        this.neu.type = "";
      }
    },
    saveEdit() {
      this.edit.error = 0;
      if (this.edit.user == "") {
        this.editBorder.user = "border border-2 border-danger";
        this.edit.error = 1;
      } else {
        this.editBorder.user = "";
      }
      if (this.edit.mail == "") {
        this.editBorder.mail = "border border-2 border-danger";
        this.edit.error = 1;
      } else {
        this.editBorder.mail = "";
      }

      if (this.edit.type == 0) {
        this.editBorder.type = "border border-2 border-danger";
        this.edit.error = 1;
      } else {
        this.editBorder.type = "";
      }

      if (this.edit.error == 0) {
        let payload = {
          globalparam: this.globalParam,
          userdata: this.edit,
        };
        this.$store.dispatch("editUSER", payload);
        this.edit.IDUser = "";
        this.edit.user = "";
        this.edit.mail = "";
        this.edit.type = "";
      }
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },

    users() {
      let users = this.$store.getters.getUSERS;
      return users;
    },
  },

  created() {
    let payload = { globalparam: this.globalParam, userdata: this.anmeldung };
    this.$store.dispatch("setInitUSERS", payload);
  },
};
</script>

<style scoped></style>
