<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter" id="t0">
          <div class=" col ps-md-5 t-center spacebetween text-uppercase">
            <div
              class="div borderRund rundKopf"
              @click="redaktionsAnzeige = 'versand'"
            >
              <span v-if="sprachID === 0">Versand</span>
              <span v-if="sprachID === 1">Delivery</span>
            </div></div>
          <div class=" col t-center spacebetween text-uppercase">
            <div
              class="div borderRund rundKopf"
              @click="redaktionsAnzeige = 'mitteilungsblatt'"
            >
              <span v-if="sprachID === 0">Mitteilungsblatt</span>
              <span v-if="sprachID === 1">newsletter</span>
            </div></div>
          <div class=" col t-center spacebetween text-uppercase">
            <div
              class="div borderRund rundKopf"
              @click="redaktionsAnzeige = 'loseblatt'"
            >
              <span v-if="sprachID === 0">Loseblattsammlung</span>
              <span v-if="sprachID === 1">loose-leaf collection</span>
            </div></div>
          <div class=" col t-center spacebetween text-uppercase"> <div
              class="div borderRund rundKopf"
              @click="redaktionsAnzeige = 'sonderhelfte'"
            >
              <span v-if="sprachID === 0">Sonderhefte</span>
              <span v-if="sprachID === 1">special issues</span>
            </div></div>
          <div class=" col t-center spacebetween text-uppercase">
           
            <div
              class="div borderRund rundKopf"
              @click="redaktionsAnzeige = 'samenfond'"
            >
              <span v-if="sprachID === 0">Samenfond</span>
              <span v-if="sprachID === 1">Samenfond</span>
            </div></div>
          <div class="col-md-10 t-center spacebetween text-uppercase">
          </div>
        </div>
        <div class="row">
          <keep-alive>
            <component :is="redaktionsAnzeige" />
          </keep-alive>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import versand from "./Versand.vue";
import sonderhelfte from "./Sonderhefte.vue";
import mitteilungsblatt from "./Mitteilungsblatt.vue";
import loseblatt from "./Loseblatt.vue";
import samenfond from "./Samenfond.vue";

export default {
  name: "Redaktionen",

  data() {
    return {
      newSite: null,
      redaktionsAnzeige: versand,
    };
  },
  components: {
    Layout,
    versand,
    sonderhelfte,
    mitteilungsblatt,
    loseblatt,
    samenfond,
  },
  methods: {
    redationsComponents() {
      if (!this.redaktionsAnzeige) {
        // console.log("artikelAnzeige: Null");
        return versand;
      } else if (
        this.redaktionsAnzeige &&
        this.redaktionsAnzeige === "versand"
      ) {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return versand;
      } else if (
        this.redaktionsAnzeige &&
        this.redaktionsAnzeige === "sonderhelfte"
      ) {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return sonderhelfte;
      } else if (
        this.redaktionsAnzeige &&
        this.redaktionsAnzeige === "mitteilungsblatt"
      ) {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return mitteilungsblatt;
      } else if (
        this.redaktionsAnzeige &&
        this.redaktionsAnzeige === "loseblatt"
      ) {
        //  console.log("artikelAnzeige: " + this.artikelAnzeige);
        return loseblatt;
      } else {
        // console.log("artikelAnzeige: " + this.artikelAnzeige);
        return versand;
      }
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
