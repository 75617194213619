<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1 v-if="sprachID === 0">Versand</h1>
        <h1 v-if="sprachID === 1">Shipping</h1>
      </div>
    </div>

    <div class="div stickyShop">
      <ul class="noDeko">
        <li class="borderRund p-3" @click="openBestellung()">
          <h2 v-if="sprachID === 0" class="bold">Bestellformular</h2>
          <h2 v-if="sprachID === 0" class="bold">Hier</h2>
          <h2 v-if="sprachID === 1" class="bold">Order form</h2>
          <h2 v-if="sprachID === 1" class="bold">Here</h2>
        </li>
      </ul>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0">
        <ul>
          <li>
            <h3 v-if="sprachID === 0">Anstecknadel</h3>
            <p v-if="sprachID === 0">
              Die Anstecknadel des AfM kann zum Preise von 3.- Euro einschl.
              Porto bestellt werden.
            </p>
            <h3 v-if="sprachID === 1">pin</h3>
            <p v-if="sprachID === 1">
              The AfM pin can be purchased at a price of EUR 3 incl. Postage to
              be ordered.
            </p>
          </li>
          <li>
            <h3 v-if="sprachID === 0">Sonderhefte</h3>
            <p v-if="sprachID === 0">
              Informationen zu verfügbaren Sonderhelften entnehmen Sie bitte der
              Seite Sonderhefte.
            </p>
            <h3 v-if="sprachID === 1">special issues</h3>
            <p v-if="sprachID === 1">
              Please see information on available special aids the Special
              Issues page.
            </p>
          </li>
          <li>
            <h3 v-if="sprachID === 0">Mitteilungsblätter</h3>
            <p v-if="sprachID === 0">
              Informationen zu verfügbaren Mitteilungsblätter entnehmen Sie
              bitte der Seite Mitteilungsblätter.
            </p>
            <h3 v-if="sprachID === 1">Newsletter</h3>
            <p v-if="sprachID === 1">
              See information on available newsletters please refer to the
              Newsletters page.
            </p>
          </li>
          <li>
            <h3 v-if="sprachID === 0">Samenfond</h3>
            <p v-if="sprachID === 0">
              Informationen zu verfügbaren Samen entnehmen Sie bitte der Seite
              Samenfond.
            </p>
            <h3 v-if="sprachID === 1">seed stock</h3>
            <p v-if="sprachID === 1">
              For information on available seeds, please refer to the Page /seed
              fund.
            </p>
          </li>
          <li>
            <h3 v-if="sprachID === 0">Übersicht der verfügbaren Literaturen</h3>
            <h3 v-if="sprachID === 1">Overview of the available literature</h3>
            <div class="row">
              <div class="col-md-3">
                <ul class="noDeko">
                  <li
                    class="borderRund"
                    @click="openPDF('Vuepdf/Literaturpreisliste2023.pdf')"
                  >
                    <span v-if="sprachID === 0" class="bold"
                      >Literaturpreisliste 2023</span
                    >
                    <span v-if="sprachID === 1" class="bold"
                      >Literature price list 2023</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0">
        <h3 v-if="sprachID === 0">Allgemeine Versandinformationen</h3>
        <h3 v-if="sprachID === 1">General shipping information</h3>

        <p v-if="sprachID === 0">
          Anfragen und Bestellungen früherer Ausgaben richten Sie bitte an den
          Leiterin der Versandstelle. <br />
          Mit der Lieferung erhalten Sie eine Rechnung und Kontoangaben.
          <br />
          Porto und Verpackung wird zum Selbstkostenpreis berechnet.
        </p>
        <p v-if="sprachID === 1">
          Please send inquiries and orders for previous issues to the head of
          the dispatch office. <br />
          With the delivery you will receive an invoice and account details.
          <br />
          Postage and packaging will be charged at cost price.
        </p>
        <h3 v-if="sprachID === 0" class="pt-4">
          Bitte nur folgende Bankverbindung benutzen!
        </h3>
        <h3 v-if="sprachID === 1" class="pt-4">
          Please only use the following bank details!
        </h3>
        <p v-if="sprachID === 0">Zahlungen richten Sie bitte an</p>
        <p v-if="sprachID === 1">Please send payments to</p>
        <p class="ps-5">
          Arbeitskreis für Mammillarienfreunde e.V.<br />
          Sparkasse Westerwald-Sieg<br />
          IBAN: DE24 5735 1030 0050 0600 86<br />
          BIC: MALADE51AKI<br />
        </p>
        <p v-if="sprachID === 0">
          Nur für Mitglieder aus nicht EU-Länder:<br />
          Zahlungen an den AfM sind über PayPal möglich.<br />
          Paypal-Konto: afm-kasse@afm-mammillaria.de 
        </p>
        <p v-if="sprachID === 1">
          Only for members from non-EU countries:<br />
          Payments to the AfM are possible via PayPal.<br />
          Paypal-Account: afm-kasse@afm-mammillaria.de 
        </p>
        <p></p>
        <p></p>
      </div>
    </div>

    <div class="row t-center justifyCenter pt-5 bg-WeissTransparent">
      <div class="col-md-3">
        <h4>
          <p v-if="sprachID === 0">Leiterin der Versandstelle:</p>
          <p v-if="sprachID === 1">Head of Shipping:</p>
        </h4>
        <P>
          <span class="bold">Marlies Schauer</span> <br />
          Bucha 1<br />
          D 94469 Deggendorf<br />
          Tel.: +49-(0)991-32454<br />

          E-Mail:
          <a class="text-Schwarz" href="mailto:afm-shop@mammillaria.eu"
            >afm-shop@mammillaria.eu
          </a>
        </P>
      </div>
      <div class="col-md-3">
        <h4>
          <p v-if="sprachID === 0">Kassierer:</p>
          <p v-if="sprachID === 1">Cashier:</p>
        </h4>
        <P>
          <span class="bold">Joachim Chupik</span> <br />
          Leipzigerstr. 25<br />
          D 63607 Wächtersbach<br />
          Tel: +49(0)6053-601465<br />

          E-Mail:
          <a class="text-Schwarz" href="mailto:afm-kassierer@mammillaria.eu"
            >afm-kassierer@mammillaria.eu
          </a>
        </P>
      </div>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Versand",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
