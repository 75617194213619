<template #default>
  <div class="container-fluid p-0 m-0 background1">
    <div class="row bg-WeissTransparent justifyCenter">
      <div class="col-md-3 text-center mt-5 p-0">
        <h1 v-if="sprachID === 0">Satzung</h1>
        <h1 v-if="sprachID === 1">Board of Directors of the AFM</h1>
      </div>
    </div>
    <div v-if="sprachID === 0" class="row bg-WeissTransparent pb-5 justifyCenter">
      <div class="col-md-10 m-3">
        <p class="">
          Diese Satzung ist am 02. August 2004 in das Vereinsregister des
          Amtsgerichtes Münster, Gerichtsstr. 2-6, 48149 Münster, unter der Nr.
          2268 eingetragen worden. Auf der JHV am 24.04.2010 wurde in §1 die
          Geschäftsadresse geändert.
        </p>
        <p class="paragraf">
          § 01 Name, Rechtsform, Sitz, Geschäftsadresse, Geschäftsjahr
        </p>
        <p class="">
          Der Verein trägt den Namen "Arbeitskreis für Mammillarienfreunde
          e.V.", im folgenden "AfM" genannt. <br />
          Der AfM ist als rechtsfähiger Verein in das Vereinsregister des
          Amtsgerichtes Münster / Westf. eingetragen <br />
          Sitz des AfM ist Münster <br />
          Geschäftsadresse des AfM ist die Anschrift des Vorsitzenden <br />
          Geschäftsjahr ist das Kalenderjahr <br />
        </p>

        <p class="paragraf">
          § 02 Zweck und Aufgaben
        </p>
        <p class="">
          Der AfM dient der Kontaktpflege und dem Erfahrungsaustausch zwischen
          Mammillarienfreunden. Darüber hinaus ist der AfM bestrebt, die
          Erforschung und die Kenntnisse über die Kakteengattung Mammillaria und
          die nahestehenden Gattungen sowie über deren Pflege zu fördern und an
          alle Mitglieder zu vermitteln. Hierzu werden Aussprachen,
          Vortragsveranstaltungen, die Herausgabe eines Mitteilungsblattes sowie
          die Bildung von Einrichtungen durchgeführt, die zur Verwirklichung
          vorgenannter Zwecke nützlich sind.
        </p>
        <p class="">
          Der AfM soll freundschaftliche Kontakte mit Fachleuten und
          Vereinigungen auf dem Gebiete der Kakteenkunde unterhalten
        </p>
        <p class="">
          Der AfM und die Tätigkeit seiner Mitglieder beruhen ausschließlich und
          unmittelbar auf ideeller Basis. Es werden ausnahmslos gemeinnützige
          Ziele im Sinne der Gemeinnützigkeitsverordnung verfolgt Der AfM
          vertritt daher weder erwerbswirtschaftliche oder parteipolitische
          Ziele noch Berufs- oder Steuerinteressen
        </p>

        <p class="paragraf">
          § 03 Mitgliedschaft und Beiträge
        </p>
        <p class="">
          Die Erlangung der Mitgliedschaft im AfM ist an keine Voraussetzung
          gebunden. Die Mitgliedschaft kann jede Person oder Vereinigung
          beaantragen.
        </p>
        <p class="">
          Der AfM hat <br /><br />
          Ehrenmitglieder <br />
          Ordentliche Mitglieder <br />
          Jugendmitglieder <br />
          Familienanschlussmitglieder <br />
        </p>
        <p class="">
          Zu Ehrenmitgliedern können vom Vorstand Personen oder Vereinigungen
          gewählt werden, die sich durch besondere Verdienste um den AfM oder um
          die Mam- millarien ausgezeichnet haben. Ehrenmitglieder sind von
          Beitragszahlungen befreit.
        </p>
        <p class="">
          Ordentliche Mitglieder können Personen oder Vereinigungen werden.
          Ordentliche Mitglieder zahlen den von der Mitgliederversammlung
          festgesetzten Beitrag.<br />
        </p>
        <p class="">
          Als Jugendmitglieder können Personen unter 18 Jahren aufgenommen
          werden. Mit Abschluss des Jahres, in dem sie ihr 18 Lebensjahr
          vollenden, werden Jugendmit- automatisch ordentliche Mitglieder.
          Jugendmitglieder zahlen die Hälfte des Beitrages der ordentlichen
          Mitglieder. <br />
        </p>
        <p class="">
          Familienanschlussmitglieder können Familienangehörige von ordentlichen
          Mitgliedern werden . Familienanschlussmitglieder zahlen den von der
          Mitgliederversammlung festgesetzten Beitrag <br />
        </p>
        <p class="">
          Der Vorstand des AfM ist ermächtigt, in Sonderfällen -jederzeit
          widerruflich - einen ermäßigten Beitrag oder einen völligen
          Beitragserlass zu gewähren. <br />
        </p>
        <p class="">
          Die Beiträge sind bis zum 31 01 jeden Jahres ohne gesonderte
          Aufforderung fällig.<br />

          Zur Erlangung der Mitgliedschaft ist ein schriftlicher Antrag an den
          Vorstand des AfM (§1, Satz 4) einzureichen. <br /><br />
          Über den Antrag entscheidet der Vorstand. Mit An- nahme des Antrages
          und Eingang der grundsätzlich zu zahlenden Bearbeitungs- gebühr – über
          deren Höhe die Mitgliederversammlung bestimmt – sowie des ersten
          Beitrages beginnt die Mitgliedschaft. Die Ablehnung eines Aufnahme-
          antrages muss nicht begründet werden. Gegen die Ablehnungbist
          Widerspruch auf der Mitgliederversammlung möglich, die endgültig
          entscheidet. Die Mitgliedschaft endet durch Austritt, Ausschluss oder
          Ableben. Der Austritt kann nur zum Ende eines Kalenderjahres erfolgen.
          Und muss bis 31.10. des je- weiligen Jahres schriftlich einem
          Vorstandsmitglied des AfM angezeigt werden. Bereits geleistete
          Beiträge verfallen mit der Beendigung der Mitgliedschaft und werden
          nicht zurückgezahlt.
        </p>
        <p class="">
          Mitglieder, die durch ihr Verhalten die gedeihliche Entwicklung des
          AfM stören oder gegen die Satzung verstoßen, können vom Vorstand aus
          dem AfM ausge- schlossen werden. Gegen den Ausschluss kann das
          ausgeschlossene Mitglied Widerspruch auf der Mitgliederversammlung
          einreichen, die darüber endgültig entscheidet; solange ruht die
          Mitgliedschaft. Bei Mitgliedern, deren Anschrift nicht feststellbar
          ist, gilt die Mitgliedschaft als beendet.
        </p>

        <p class="paragraf">
          § 04 Rechte und Pflichten der Mitglieder
        </p>
        <p class="">
          AIle Mitglieder haben grundsätzlich die gleichen Rechte und Pflichten.
          Zu den Rechten der Mitglieder zählen insbesondere das Stjmmrecht auf
          der Mitgliederversammlung, die Teilnahme an den Veranstaltungen des
          AfM, die Nutzung der Einrichtungen des AfM, sowie der im Beitrag
          eingeschlossene Bezug des Mitteilungsblattes des AfM.
          Familienanschlussmitglieder haben keinen Anspruch auf den Bezug des
          Mitteilungsblattes.
        </p>
        <p class="">
          Die Mitglieder haben die Pflicht, sich nach den Bestimmungen der
          Satzung zu richten, Zweck und Aufgaben des AfM aktiv zu unterstützen,
          das Ansehen des AfM zu fördern, und durch ihr Verhalten - insbesondere
          pünktliche Beitragsentrichtung unnötige Belastungen des AfM zu
          vermeiden
        </p>
        <p class="">
          Der Vorstand ist ermächtigt, zur Unterstützung seiner Arbeit Beisitzer
          zu ernennen
        </p>
        <p class="">
          Der Vorstand wird durch die Mitgliederversammlung für 2 Jahre gewählt.
          Die Wiederwahl ist zulässig , Die Vorstandsmitglieder bleiben solange
          im Amt, bis Nachfolger ordnungs- gemäß gewählt sind .
        </p>
        <p class="">
          Alle Unterlagen des Vorstandes und der Einrichtungen sind Eigentum des
          AfM und bei Ausscheiden aus dem Amt unaufgefordert vollständig und
          ordnungsgemäß an den Nachfolger zu übergeben. Für die Aufbewahrung
          gilt die gesetzliche Regelung. Die Vernichtung von Unterlagen bedarf
          eines Vorstandsbeschlusses.
        </p>

        <p class="paragraf">
          § 05 Organe
        </p>
        <p class="">
          Die Organe des AfM sind der Vorstand und die Mitgliederversammlung.
        </p>
        <p class="paragraf">
          § 06 Vorstand
        </p>
        <p class="">
          Den Vorstand im Sinne des § 26 BGB bilden der Vorsitzende, der
          Schriftführer und der Kassierer. Jedes Vorstandsmitglied ist allein
          vertretungsberechtigt. Im lnnenverhältnis verfahren die
          Vorstandsmitglieder gemäß der Aufgabenteilung, die sie vereinbaren.
        </p>
        <p class="">
          Im Falle des Ausscheidens eines Vorstandsmitgliedes während seiner
          Amtszeit können die übrigen Vorstandsmitglieder eine Ergänzungswahl
          vornehmen. Die Wahl unterliegt der Bestätigung auf der nächsten
          Mitgliederversammlung
        </p>
        <p class="">
          Der Vorstand ist ermächtigt zur Unterstützung seiner Arbeit Beisitzer
          zu ernennen. Der Vorstand wird durch die Mitgliederversammlung für 2
          Jahre gewählt. Die Wiederwahl ist zulässig. Die Vorstandsmitglieder
          bleiben solange im Amt, bis Nachfolger ordnungsgemäß gewählt sind .
        </p>
        <p class="">
          Alle Unterlagen des Vorstandes und der Einrichtungen sind Eigentum des
          AfM und bei Ausscheiden aus dem Amt unaufgefordert vollständig und
          ordnungsge¬mäß an den Nachfolger zu übergeben. Für die Aufbewahrung
          gilt die gesetzliche Regelung. Die Vernichtung von Unterlagen bedarf
          eines Vorstandsbeschlusses.
        </p>
        <p class="paragraf">
          § 07 Mitgliederversammlung
        </p>
        <p class="">
          Die Mitgliederversammlung wird einmal jährlich - möglichst in der 1
          Jahreshälfte - vom Vorsitzenden des AfM einberufen Die Einladung
          hierzu - mit Angabe der Tagesordnung - ist spätestens 4 Wochen vorher
          im Mitteilungsblatt des AfM zu veröffentlichen Die Einberufung einer
          außerordentlichen Mitgliederversammlung kann im Bedarfsfall vom
          Vorstand jederzeit vorgenommen werden. Bei wesentlichen Veränderungen
          oder wichtigen Ereignissen soll sie unverzüglich erfolgen. Im übrigen
          hat die Einberufung einer außerordentlichen Mitgliederversammlung auf
          Antrag von mindestens 1/10 der Mitglieder des AfM zu erfolgen, Die
          ordnungsgemäß einberufene Mitgliederversammlung ist ohne Rücksicht auf
          die Zahl der erschienenen Mitglieder beschlussfähig. Die
          Mitgliederversammlung wird vom Vorsitzenden oder einem Vertreter
          geleitet. Über den Ablauf der Mitgliederversammlung ist ein Protokoll
          anzufertigen
        </p>
        <p class="">
          Die Mitgliederversammlung hat folgende Aufgaben:<br />
          Genehmigung des Protokolls der letzten Mitgliederversammlung. <br />
          Entgegennahme des Rechenschafts-und Kassenberichtes für das
          abgelaufene Geschäftsjahr. <br />
          Entlastung des Vorstandes auf Antrag der Kassenprüfer.<br />
          Neuwahl eines Vorstandes alle 2 Jahre.<br />
          Wahl eines Kassenprüfers jährlich für eine 2-jährige Amtszeit. <br />
          Festsetzung der Höhe der Mitgliedsbeiträge. <br />
          Abstimmung über eingereichte Anträge und Ehrungen.<br />
          Beschlussfassung über Ort und voraussichtlichen Zeitpunkt der
          übernächsten Mitgliederversammlung<br />
        </p>
        <p class="">
          Anträge zur Abstimmung auf der Mitgliederversammlung kann jedes
          Mitglied stellen. Sie sind 3 Monate vor der Mitgliederversammlung bei
          dem Vorsitzenden einzureichen. Eine Information der Mitglieder über
          vorliegende Anträge hat spätestens im letzten Mitteilungsblatt des AfM
          vor der Mitgliederversammlung zu erfolgen.
        </p>
        <p class="">
          Jedes anwesende Mitglied hat bei Abstimmungen jeweils nur 1 Stimme.
          Bei der Abstimmung über Satzungsänderungen und über die Auflösung des
          AfM entscheidet die 2/3-Mehrheit; In allen anderen Fällen die einfache
          Mehrheit. Bei Stimmengleichheit gilt ein Antrag als abgelehnt.
        </p>

        <p class="paragraf">
          § 08 Verwendung der Mittel
        </p>
        <p class="">
          Der AfM ist kein auf Gewinnerzielung ausgerichteter Verein. Die Mittel
          des AfM dürfen nur zu den satzungsgemäßen Zwecken verwendet werden .
          Die Wahrnehmung der Ämter und Aufgaben im AfM geschieht ehrenamtlich
          bei Erstattung der Auslagen. Die Mitglieder erhalten keine
          Gewinnanteile des AfM. Bei Beendigung der Mitgliedschaft oder bei
          Auflösung des AfM haben die Mitglieder keinen Anspruch auf das
          Vermögen des AfM. Im Falle der Auflösung des AfM oder bei Fortfall
          seines bisherigen Zweckes ist ein nach Erfüllung der Verbindlichkeiten
          eventuell verbleibender Überschuss des Vermögens an die Städtische
          Sukkulenten Sammlung Zürich (Mythenquai 88, CH-8002 Zürich) zu
          übertragen Vorstehende Maßnahmen über die Verwendung des eventuellen
          Vermögens dürfen allerdings erst nach Einwilligung des zuständigen
          Finanzamtes vorgenommen werden
        </p>

        <p class="paragraf">
          § 09 Schiedsgericht
        </p>
        <p class="">
          Streitigkeiten aufgrund der Mitgliedschaft im AfM oder in sonstigen
          Vereinsangelegenheiten werden durch ein Schiedsgericht entschieden.
          Das Schiedsgericht setzt sich zusammen aus 5 Mitgliedern des AfM, die
          bei Bedarf auf der nächsten Mitgliederversammlung gewählt werden . Bis
          zu diesem Zeitpunkt ruhen die Streitigkeiten.
        </p>

        <p class="paragraf">
          § 10 Inkrafttreten der Satzung
        </p>
        <p class="">
          Diese Satzung wird nach Beschlussfassung durch die
          Mitgliederversammlung vom 08. Ma i 2004 gemäß § 71 Abs.l S.l BGB durch
          Eintragung in das Vereinsregister wirksam.
        </p>
        <p class="">
          Vorstehende Satzung ist am 02. August 2004 in das Vereinsregister des
          Amtsgerichtes Münster, Gerichtsstr. 2-6, 48149 Münster, unter der Nr
          2268 eingetragen worden .
        </p>

        <p class="">
          Der Vorstand
        </p>
      </div>
    </div>
    <div v-if="sprachID === 1" class="row bg-WeissTransparent pb-5 justifyCenter">
      <div class="col-md-10 m-3">
        <p class="">
          This statute was entered in the register of associations on August 2nd, 2004
          District Court of Münster,gerichtstr. 2-6, 48149 Münster, under the no.
          2268 registered. At the AGM on April 24th, 2010, in §1 the
          Business address changed.
        </p>
        <p class="paragraph">
          § 01 Name, legal form, registered office, business address, financial year
        </p>
        <p class="">
          The association bears the name "Working Group for Mammillaria Friends
          e.V.", hereinafter referred to as "AfM". <br />
          The AfM is registered as an association with legal personality in the register of associations
          Registered at the District Court of Münster / Westphalia <br />
          The AfM is based in Münster <br />
          The business address of the AfM is the address of the chairman <br />
          Fiscal year is the calendar year <br />
        </p>

        <p class="paragraph">
          § 02 Purpose and Tasks
        </p>
        <p class="">
          The AfM serves to maintain contacts and exchange experiences between
          mammillaria friends. In addition, the AfM endeavors to
          Exploration and knowledge of the cactus genus Mammillaria and
          to promote and support the related genera and their care
          to convey to all members. To this end, debates
          Lecture events, the publication of a newsletter as well as
          the formation of bodies carried out leading to the attainment
          the aforementioned purposes are useful.
        </p>
        <p class="">
          The AfM is intended to establish friendly contacts with experts and
          maintain associations in the field of cactus science
        </p>
        <p class="">
          The AfM and the activities of its members are based exclusively and
          directly on an ideal basis. Without exception, it will be non-profit
          The AfM pursues goals in the sense of the non-profit regulation
          therefore represents neither commercial nor party-political
          Goals nor professional or tax interests
        </p>

        <p class="paragraph">
          § 03 Membership and Contributions
        </p>
        <p class="">
          Obtaining membership in the AfM is not a prerequisite
          bound. Membership can be any person or association
          request.
        </p>
        <p class="">
          The AfM has <br /><br />
          Honorary Members <br />
          Ordinary Members <br />
          youth members <br />
          Family members <br />
        </p>
        <p class="">
          The board of directors can appoint persons or associations to become honorary members
          are elected who are distinguished by special merits to the AfM or to
          who have distinguished Mammillaria. Honorary members are from
          Contribution payments exempt.
        </p>
        <p class="">
          Ordinary members can be persons or associations.
          Ordinary members pay by the General Assembly
          fixed contribution.<br />
        </p>
        <p class="">
          Persons under the age of 18 can be accepted as youth members
          will. By the end of the year in which she turned 18
          complete, youth members automatically become full members.
          Youth members pay half of the regular membership fee
          members. <br />
        </p>
        <p class="">
          Family members can be family members of ordinary
          become members . Family members pay the fee charged by the
          Membership fixed contribution <br />
        </p>
        <p class="">
          The board of the AfM is authorized, in special cases - at any time
          revocable - a reduced contribution or a full one
          to grant a fee waiver. <br />
        </p>
        <p class="">
          The contributions are up to 31 01 of each year without separate
          Request due.<br />

          To obtain membership, a written application must be sent to the
          Board of the AfM (§1, clause 4) to be submitted. <br /><br />
          The Board of Directors decides on the application. With acceptance of the application
          and receipt of the processing fee to be paid in principle – via
          the amount of which is determined by the general assembly – as well as the first
          membership begins. The refusal of an admission
          application does not have to be justified. Against the rejection
          Objection possible at the general meeting, which is final
          decides. Membership ends by resignation, expulsion or
          demise. Resignation can only take place at the end of a calendar year.
          And must by 31.10. of the respective year in writing
          Vorstnds member of the AfM are displayed. Already done
          Contributions expire upon termination of membership and are
          not refunded.
        </p>
        <p class="">
          Members who, through their behavior, contribute to the prosperous development of the
          Disrupting AfM or violating the statutes can be done by the board of directors
          be excluded from the AfM. Against the exclusion that can
          excluded member objection at the general meeting
          submit the final decision on this; as long as she rests
          Membership. For members whose address cannot be determined
          is, the membership is considered terminated.
        </p>

        <p class="paragraph">
          § 04 Rights and duties of the members
        </p>
        <p class="">
          In principle, all members have the same rights and obligations.
          The rights of the members include, in particular, the right to vote
          the General Assembly, participation in the events of the
          AfM, the use of the facilities of the AfM, as well as in the article
          included reference to the newsletter of the AfM.
          Family members are not entitled to receive the
          newsletter.
        </p>
        <p class="">
          The members have the duty to comply with the provisions of the
          to judge the statutes, to actively support the purpose and tasks of the AfM,
          to promote the reputation of the AfM, and through their behavior - in particular
          punctual payment of contributions to unnecessary burdens on the AfM
          avoid
        </p>
        <p class="">
          The Executive Board is authorized to have assessors to support its work
          to appoint
        </p>
        <p class="">
          The Board of Directors is elected by the General Assembly for 2 years.
          Re-election is permitted, the board members remain as long as
          in office until successors are duly elected .
        </p>
        <p class="">
          All documents of the board and the institutions are the property of the
          AfM and when leaving office without being asked completely and
          duly handed over to the successor. For storage
          the legal regulation applies. The destruction of documents requires
          a board decision.
        </p>

        <p class="paragraph">
          § 05 Organs
        </p>
        <p class="">
          The organs of the AfM are the Board of Directors and the General Assembly.
        </p>
        <p class="paragraph">
          § 06 Executive Board
        </p>
        <p class="">
          The board of directors within the meaning of Section 26 of the German Civil Code is formed by the chairman who
          secretary and the cashier. Each board member is alone
          authorized to represent. The procedure in the internal relationship
          Board members according to the division of responsibilities they agree upon.
        </p>
        <p class="">
          In the event that a member of the Management Board resigns during his
          term of office, the remaining members of the Board of Directors can elect a supplementary member
          make. The choice is subject to confirmation on the next
          General Assembly
        </p>
        <p class="">
          The Board of Directors is authorized to support its work as an assessor
          to appoint. The board of directors is appointed by the general meeting for 2
          years elected. Re-election is permissible. The board members
          remain in office until successors are duly elected.
        </p>
        <p class="">
          All documents of the board and the institutions are the property of the
          AfM and when leaving office without being asked completely and
          properly handed over to the successor. For storage
          the legal regulation applies. The destruction of documents requires
          a board decision.
        </p>
        <p class="paragraph">
          § 07 General Assembly
        </p>
        <p class="">
          The General Assembly is held once a year - if possible in the 1st
          Half of the year - convened by the chairman of the AfM The invitation
          this - with details of the agenda - is at least 4 weeks in advance
          to be published in the AfM newsletter
          Extraordinary General Assembly can be held from
          board can be made at any time. For significant changes
          or important events, it should take place immediately. Furthermore
          has the right to convene an extraordinary general meeting
          Application to be made by at least 1/10 of the members of the AfM, Die
          duly convened general meeting is without regard to
          the number of appeareden members quorate. the
          General meeting is chaired by the chairman or a representative
          directed. There is a record of the course of the general meeting
          to make
        </p>
        <p class="">
          The General Assembly has the following tasks:<br />
          Approval of the minutes of the last general meeting. <br />
          Receipt of the accounting and cash report for the
          past financial year. <br />
          Discharge of the board at the request of the auditors.<br />
          New election of a board every 2 years.<br />
          Election of a Treasurer annually for a 2-year term. <br />
          Determination of the amount of membership fees. <br />
          Voting on submitted proposals and honors.<br />
          Resolution on the place and probable date of the
          next but one general meeting<br />
        </p>
        <p class="">
          Anyone can submit a motion to vote at the General Assembly
          make a member. You are present 3 months before the general meeting
          submit to the chairman. An information of the members about
          existing applications has at the latest in the last newsletter of the AfM
          to be made before the General Assembly.
        </p>
        <p class="">
          Each member present has only 1 vote at a time.
          When voting on amendments to the Articles of Association and on the dissolution of the
          AfM decides the 2/3 majority; In all other cases the simple one
          Majority. In the event of a tie, an application is deemed to have been rejected.
        </p>

        <p class="paragraph">
          § 08 Use of Funds
        </p>
        <p class="">
          The AfM is not a profit-making association. The means
          of the AfM may only be used for the statutory purposes.
          The perception of the offices and tasks in the AfM is done on a voluntary basis
          upon reimbursement of expenses. Members do not receive any
          Profit shares of the AfM. Upon termination of membership or at
          The members have no right to the dissolution of the AfM
          Assets of the AfM. In the event that the AfM is dissolved or ceases to exist
          its previous purpose is one after the fulfillment of obligations
          any remaining surplus of assets to the municipal
          Succulent Collection Zurich (Mythenquai 88, CH-8002 Zurich).
          transfer the above measures on the use of the eventual
          Assets may, however, only after the consent of the responsible
          be made by the tax office
        </p>

        <p class="paragraph">
          § 09 Court of Arbitration
        </p>
        <p class="">
          Disputes due to membership in the AfM or in others
          Association matters are decided by an arbitration board.
          The arbitral tribunal consists of 5 members of the AfM, who
          be elected at the next general meeting if necessary. until
          at this time the disputes are dormant.
        </p>

        <p class="paragraph">
          § 10 Entry into force of the Articles of Association
        </p>
        <p class="">
          These statutes will be adopted after the resolution by the
          General meeting of May 8th, 2004 according to § 71 Abs.l S.l BGB
          Entry in the register of associations effective.
        </p>
        <p class="">
          The above statutes were entered into the register of associations on August 2nd, 2004
          District Court of Münster,gerichtstr. 2-6, 48149 Münster, under the no
          2268 registered.
        </p>

        <p class="">
          The board
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Satzung",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
