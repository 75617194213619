<template>
  <carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
    <slide v-for="slide in slides" :key="slide">
      <router-link
        to="/Praxis"
        ><img  :src="getImgUrl(slide)" v-bind:height="400" v-bind:width="600"  
      /></router-link>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "carouselRem",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/REM/", false, /\.jpg$/);
     // console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      "rem1",
      "rem2",
      "rem3",
      "rem4",
      "rem5",
      "rem6",
      "rem7",
      "rem8",
      "rem9",
      "rem10",
      "rem11",
      "rem12",
    ];
    return {
      slides,
    };
  },
};
</script>
