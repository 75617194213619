<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-12 col-md-8 m-0 p-0 text-Rot-Schatten text-center">
        
        <h1 v-if="sprachID === 0">Literaturverzeichnisse</h1>
        <h1 v-if="sprachID === 1">Bibliographies</h1>
      </div>
    </div>


    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-12 col-md-6 m-0 pt-5">
        <p>
         
        </p>
        <ul class="noDeko">
          <li class="borderRund"
            @click="openPDF('Vuepdf/Buecherverzeichnis.pdf')">
            <span class="bold" v-if="sprachID === 0">Bücherverzeichnis</span>
            <span class="bold" v-if="sprachID === 1">Book directory</span>
          </li>
          <li class="borderRund"
            @click="openPDF('Vuepdf/literatur.pdf')">
            <span class="bold" v-if="sprachID === 0">Literaturverzeichnis</span>
            <span class="bold" v-if="sprachID === 1">Bibliographies</span>
          </li>
          <li class="borderRund"
            @click="openPDF('Vuepdf/periodica.pdf')">
            <span class="bold" v-if="sprachID === 0">Periodica</span>
            <span class="bold" v-if="sprachID === 1">Periodica</span>
          </li>
          <li class="borderRund"
            @click="openPDF('Vuepdf/schriftenreihe.pdf')">
            <span class="bold" v-if="sprachID === 0">Schriftenreihe</span>
            <span class="bold" v-if="sprachID === 1">Publication series</span>
          </li>
          
         
        </ul>
      
      </div>
    </div>

  </div>

  
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Loseblatt",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
