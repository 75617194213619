<template #default>
  <div class="container-fluid p-0 m-0 background1">
    <div class="row bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-6 mt-5 p-0">
        <h1 v-if="sprachID === 0">Wo finde ich was?</h1>
        <h1 v-if="sprachID === 1">Board of Directors of the AFM</h1>
      </div>
    </div>
    <div class="row bg-WeissTransparent pb-5 justifyCenter">
      <div class="col-11 col-md-6">
        <ul>
          <li>
            <a href="home.html" target="inhalt">
              <span class="bold yellow">Home</span>
            </a>
            <ul>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Titelseite</span
                >
                <span v-if="sprachID === 1" class="bold yellowLight">
                  front page</span
                >
                <p v-if="sprachID === 0">
                  Wir begrüßen Sie auf unserer Homepage
                </p> <p v-if="sprachID === 1">
                  We welcome you on our Homepage
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  aktuelles</span
                ><span v-if="sprachID === 1" class="bold yellowLight">
                  news</span
                >
                <p v-if="sprachID === 0">Immer das neuste vom Vereinsleben</p>
                <p v-if="sprachID === 1">Always the latest from club life</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Wie werde ich Mitglied</span
                ><span v-if="sprachID === 1" class="bold yellowLight">
                  How do I join</span
                >
                <p v-if="sprachID === 0">
                  Aufnahmeantrag und Einzugsermächtigung
                </p>
                <p v-if="sprachID === 1">
                 Application for admission and direct debit authorization
                </p>
              </li>
              
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Wo finde ich was</span
                >
                <span v-if="sprachID === 1" class="bold yellowLight">
                  Where do I find what</span
                >
                <p v-if="sprachID === 0">Dieses hier</p>
                <p v-if="sprachID === 1">This here</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Webmaster</span
                >
                <p v-if="sprachID === 0">Email an den Webmaster</p>
                <p v-if="sprachID === 1">Email to the webmaster</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Befreundete Seiten
                </span>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  Friendly Pages
                </span>
                <p v-if="sprachID === 0">
                  Andere Internetseiten über unser Thema
                </p>
                <p v-if="sprachID === 1">Other websites about our topic
                </p>
              </li>
            </ul>
          </li>
          <li>
            <a href="organisation.html" target="inhalt">
              <span v-if="sprachID === 0" class="bold yellow"> Vorstand</span>
              <span v-if="sprachID === 1" class="bold yellow"> Executive</span>
            </a>

            <ul>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight"> Vorstand</span>
                <p v-if="sprachID === 0">Kontaktadressen des Vorstandes</p>
                <span v-if="sprachID === 1" class="bold yellowLight"> Executive</span>
                 <p v-if="sprachID === 1">Contact addresses of the executive</p>
              </li>
            </ul>
          </li>
          <li>
            <a href="medien.html" target="inhalt">
              <span class="bold yellow">Medien</span>
            </a>
            <ul>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  REM-Archiv</span
                >
                <span v-if="sprachID === 1" class="bold yellowLight">
                  REM-Archive</span
                >
                <p v-if="sprachID === 0">Liste der vorhandenen Aufnahmen</p>
                <p v-if="sprachID === 1">List of existing recordings</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Bibliothek</span
                ><span v-if="sprachID === 1" class="bold yellowLight">
                  library</span
                >
                <p v-if="sprachID === 0">Wo kann ich was ausleihen?</p>
                <p v-if="sprachID === 1">Where can I borrow something??</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Feldlisten</span
                >
                <span v-if="sprachID === 1" class="bold yellowLight">
                  field lists</span
                >
                <p v-if="sprachID === 0">
                  Standortangaben verschiedener Kakteenkenner
                </p>
                <p v-if="sprachID === 1">
                  Location information from various cactus connoisseurs
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Erstbeschreibungen</span
                >
                <span v-if="sprachID === 1" class="bold yellowLight">
                  first descriptions</span
                >
                <p v-if="sprachID === 0">
                  Sammlung der bei AfM vorhandenen Texte
                </p>
                <p v-if="sprachID === 1">
                  Collection of the texts available at AfM
                </p>
              </li>
            </ul>
          </li>
          <li>
            <a href="http://joerg21.magix.net" target="inhalt">
              <span v-if="sprachID === 0" class="bold yellow">
                Fotogalerie</span
              >
              <span v-if="sprachID === 1" class="bold yellow">
                photo gallery</span
              >
            </a>
            <ul>
              <li>
                <span class="bold yellowLight"> Coryphantha</span>
                <p v-if="sprachID === 0">Standortaufnahmen</p>
                <p v-if="sprachID === 1">location shots</p>
              </li>
              <li>
                <span class="bold yellowLight"> Escobaria</span>
                <p v-if="sprachID === 0">Standortaufnahmen</p>
                <p v-if="sprachID === 1">location shots</p>
              </li>
              <li>
                <span class="bold yellowLight"> Mammillaria</span>
                <p v-if="sprachID === 0">Standortaufnahmen</p>
                <p v-if="sprachID === 1">location shots</p>
              </li>
              <li>
                <span class="bold yellowLight"> Neolloydia</span>
                <p v-if="sprachID === 0">Standortaufnahmen</p>
                <p v-if="sprachID === 1">location shots</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight"> Sammlungspflanzen</span>
                <p v-if="sprachID === 0">
                  Aufnahmen aus verschiedenen Sammlungen
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight"> collection plants</span>
                <p v-if="sprachID === 1">
                  Recordings from different collections
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight"> Standorte, Landschaften</span>
                <p v-if="sprachID === 0">
                  Aufnahmen von Menschen, Gebäuden und Standorten
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight"> locations, landscapes</span>
                <p v-if="sprachID === 1">
Images of people, buildings and locations                </p>
              </li>
            </ul>
          </li>
          <li>
            <a href="redaktionen.html" target="inhalt">
              <span v-if="sprachID === 0" class="bold yellow">Redaktionen</span>
              <span v-if="sprachID === 1" class="bold yellow">editorial offices</span>
            </a>
            <ul>
              <li>
                <span class="bold yellowLight"> Mitteilungsblatt</span>
                <p v-if="sprachID === 0">
                  Alles Wissenswerte über die Zeitschrift des AfM
                </p>
                <span class="bold yellowLight"> Newsletter</span>
                <p v-if="sprachID === 1">
                  Everything you need to know about the AfM magazine
                </p>
              </li>
              <li>
                <span class="bold yellowLight"> Loseblattsammlung</span>
                <p v-if="sprachID === 0">
                  Sammlung von Pflanzenbeschreibungen, die laufend ergänzt wird.
                </p>
                <span class="bold yellowLight"> loose-leaf collection</span>
                <p v-if="sprachID === 1">
                  Collection of plant descriptions that is constantly being
                  expanded.
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Sonderhefte</span
                >
                <p v-if="sprachID === 0">
                  Informationen zu unseren Sonderheften
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  special issues</span
                >
                <p v-if="sprachID === 1">
                  Information about our special issues
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Versand der Publikationen</span
                >
                <p v-if="sprachID === 0">
                  Wer verschickt die aktuellen Veröffentlichungen?
                </p>
                <p v-if="sprachID === 0">
                  Wo und wie kann ich ältere Publikationen bekommen?
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  Delivery of the publications</span
                >
                <p v-if="sprachID === 1">Who sends the current publications?</p>
                <p v-if="sprachID === 1">
                  Where and how can I get older publications?
                </p>
              </li>
            </ul>
          </li>
          <li>
            <a href="veranstaltungen.html" target="inhalt">
              <span v-if="sprachID === 0" class="bold yellow"
                >Veranstaltungen</span
              >
              <span v-if="sprachID === 1" class="bold yellow">events</span>
            </a>
            <ul>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Jahreshauptversammlung</span
                >
                <p v-if="sprachID === 0">Programm und Berichte</p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  annual general meeting</span
                >
                <p v-if="sprachID === 1">program and reports</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Herbsttagung</span
                >
                <p v-if="sprachID === 0">Programm und Berichte</p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  autumn conference</span
                >
                <p v-if="sprachID === 1">program and reports</p>
              </li>
            </ul>
          </li>
          <li>
            <a href="shop.html" target="inhalt">
              <span class="bold yellow">Shop</span>
            </a>
            <ul>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Materialienversand
                </span>
                <p v-if="sprachID === 0">
                  Alles über den Bezug von Publikationen des AfM
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  Materials Shipping
                </span>
                <p v-if="sprachID === 1">
                  Everything about purchasing AfM publications
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Samenfond</span
                >
                <p v-if="sprachID === 0">
                  Angebot und Bezugsmöglichkeiten von Samen
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  seed stock</span
                >
                <p v-if="sprachID === 1">
                  Supply and procurement options for seeds
                </p>
              </li>
            </ul>
          </li>
          <li>
            <a href="sonstiges.html" target="inhalt">
              <span v-if="sprachID === 0" class="bold yellow"> Sonstiges</span>
              <span v-if="sprachID === 1" class="bold yellow">
                Miscellaneous</span
              >
            </a>
            <ul>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Pflanzensammlung</span
                >
                <p v-if="sprachID === 0">
                  Informationen über die Pflanzensammlung des AfM
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  Plant Collection</span
                >
                <p v-if="sprachID === 1">
                  Information about the plant collection of the AfM
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Interna</span
                >
                <p v-if="sprachID === 0">
                  Zugang zu den internen Seiten dieser Homepage
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  Internals</span
                >
                <p v-if="sprachID === 1">
                  Access to the internal pages of this homepage
                </p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Zugang zu Interna anfordern</span
                >
                <p v-if="sprachID === 0">Passwortanforderung für Mitglieder</p>
                <span v-if="sprachID === 1" class="bold yellowLight">
                  Request access to internals</span
                >
                <p v-if="sprachID === 1">Password requirement for members</p>
              </li>
              <li>
                <span v-if="sprachID === 0" class="bold yellowLight">
                  Anzeigenannahme</span
                >
                <p v-if="sprachID === 0">
                  Hier können Sie Ihre Anzeige schalten
                </p>
                <span v-if="sprachID === 1" class="bold yellowLight"
                  >Ad acceptance</span
                >
                <p v-if="sprachID === 1">Your ad can be placed here</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Satzung",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
