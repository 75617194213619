<template>
  <carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
    <slide v-for="slide in slides" :key="slide">
      <router-link
        to="/Praxis"
        ><img  :src="getImgUrl(slide)" v-bind:height="210" v-bind:width="250" 
      /></router-link>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "carouselBibliothek",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/Bibliothek/", false, /\.jpg$/);
     // console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      "b1",
      "b2",
      "b3",
      "b4",
      "b5",
      "b6",
      "b7",
      "b8",
      "b9",
      "b9",
    ];
    return {
      slides,
    };
  },
};
</script>
