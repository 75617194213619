<template>
  <div class="card text-center text-vue modaleKlein" >
    <div class="card-body">
      <div  class="modaleInner">
        <vue-pdf-embed :source="'http://mammillaria.eu/' + pdf" />
      </div>

      <button
        type="button"
        class="mt-3 btn btn-danger me-5"
        @click="download()"
      >
        download
      </button>
      <button type="button" class="mt-3 btn btn-danger ma-5" @click="close()">
        Close
      </button>
    </div>
    <!-- Modal footer -->
  </div>
</template>

<script>
import { closeModal } from "jenesius-vue-modal";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "ModalsPDF",
  props: {
    pdf: String,
  },
  methods: {
    close() {
      closeModal();
    },
    download() {
      var win = window.open("http://mammillaria.eu/" + this.pdf, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
  },
  components: {
    VuePdfEmbed,
  },
  created() {},
};
</script>
