<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
          <div class="col-11 col-md-8 m-0 p-0">
            <span class=" text-Rot-Schatten"  v-if="sprachID === 0">Publikationen</span>
            <span class=" text-Rot-Schatten" v-if="sprachID === 1">Publications</span>
          </div>
        </div>
        <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
          <div class="col-12 col-md-8 m-0 p-0">
            <ul>
              <li>                
                <h2 v-if="sprachID === 0">Mitteilungsblätter</h2>
                <h2 v-if="sprachID === 1">Letter</h2>
                <p v-if="sprachID === 0">
                  Das Mitteilungsblatt wird seit 1977 herausgegeben. Der Inhalt
                  ist eine Mischung aus Berichten zur Taxonomie und Nomenklatur
                  von Pflanzen (vor allem der Gattung Mammillaria, aber auch der
                  Gattungen Coryphantha, Escobaria und Neolloydia ) ,
                  wissenschaftlichen Abhandlungen, Erstbeschreibungen,
                  Reiseberichten, Feldnotizen, Kulturhinweisen, Kurzberichten
                  und vielen anderen Themengebieten. <br /><br />
                  Seit 1994 erscheint das Arbeitsmaterial für Mitglieder des AfM
                  alle 3 Monate mit 64 Innenseiten und Farbdrucken. <br /><br />
                  Eine Aufstellung der erschienenen Mitteilungsblätte finden Sie
                  unter Shop/Mitteilungsblatt.
                </p>

                <div class="row p-0 mb-3">
                  <div class="col-12 col-md-3 p-0">Redakteur:</div>
                  <div class="col-12 col-md-6">
                    Othmar Appenzeller <br />
                    Erikastr 9<br />
                    D 66424 Homburg/ Saar <br />
                    Tel.:+49 (0)6841-2896
                    <br />E-Mail: <a class="text-Schwarz" href="mailto:afm-shop@mammillaria.eu">
                      afm-redaktion-mtbl@mammillaria.eu
                      </a>
                  </div>
                  <div class="col-3"></div>
                  <div class="col-3"></div>
                </div>
             <div class="row p-0 mb-3">
                  <div class="col-12 col-md-3 p-0">Redaktionelle Mitarbeit:</div>
                  <div class="col-12 col-md-3 py-2 py-md-0">
                    Wolfgang Berthold <br />
                    Zwickauer Straße 470 <br />
                    D 09117 Chemnitz <br />
                    Tel.: +49 (0)371-8201957 <br />
                  </div>
                  <div class="col-12 col-md-3 py-2 py-md-0">
                    Wolfgang John <br />
                    Kastanienallee 2 <br />
                    D-99610 Wundersleben <br />
                    Tel. +49(0)36376-53366 <br />
                  </div>
                  <div class="col-12 col-md-3 py-2 py-md-0">
                    Grzegorz Matuszewski <br />
                    Ul Malinowa 37 <br />
                    PL 97-400 Betchatów <br />
                    Tel.: +48 - 502122505 <br />
                    Fax.: +48 - 446359010 <br />
                  </div>
                </div>
                <div class="row p-0 mb-3">
                  <div class="col-12 col-md-3 p-0">Layout:</div>
                  <div class="col-12 col-md-3 py-2 py-md-0">
                    Tom Twijnstra <br />
                    Statensingel 66a <br />
                    NL-3039 LR Rotterdam <br />
                    Tel.: +31 (0)6-24160593 <br />
                  </div>
                  <div class="col-12 col-md-3 p-0">Versand des Mitteilungsblattes:</div>
                  <div class="col-12 col-md-3 py-2 py-md-0">                    
                    Sabine Chupik <br />
                    Leipziger Str. 25 <br />
                    D-63607 Wächtersbach <br />
                    Tel.:+49(0)6053-601465 <br />
                  </div>
                </div>
                <div class="row p-0 mb-3">
                  <div class="col-12 col-md-3 p-0">Übersetzungen:</div>
                  <div class="col-12 col-md-3 py-2 py-md-0">
                    Othmar Appenzeller <br />
                    Karl-Heinz Frackowiak <br />
                    Roger Preen <br />
                  </div>
                  <div class="col-12 col-md-3 py-2 py-md-0">
                    Chris Davies <br />
                    Eberhard Lutz <br />
                    Klaus J. Schuhr <br />
                    </div>
                  <div class="col-12 col-md-3 py-2 py-md-0">
                    Ralf Dehn <br />
                    Rainer Pillar <br />
                    Wolter ten Hoeve
                    </div>
                </div>
                

                <h2 v-if="sprachID === 1">newsletters</h2>
                <p v-if="sprachID === 1">
                  See information on available newsletters please the
                  newsletters page
                </p>
              </li>
              <li>
                <h2 v-if="sprachID === 0">Sonderhefte</h2>
                <p v-if="sprachID === 0">
                 Seit 2017 erscheinen in unregelmäßigen Abständen Sonderhefte. Diese befassen sich jeweils mit einem speziellen Thema.
                </p>
                <p>
                 Bisher erschienen sind :
                 <ul>
                  <li>2017 - Das Erbe Ehrenbergs von Othmar Appenzeller</li>
                  <li>2018 - Die Sierra Mixteca Alta als Lebensraum im Wandel der Zeit von Klaus Rebmann</li>
                  <li>2019 - Georg Engelmann und seine Mammillarien von Othmar Appenzeller</li>
                  <li>2021 - Die Reihe Leucocephalae Teil 1 von Holger Rudzinski</li>
                  <li>2022 - (vergriffen)  Emil Heese, James Alberto McDowell und Mammillaria stella-de-tacubaya von Holger Rudzinski</li>
                 </ul>
                </p>
                <div class="row p-0 mb-3">
                  <div class="col-12 col-md-3 p-0">Redakteur:</div>
                  <div class="col-12 col-md-6 p-0">
                    Othmar Appenzeller <br />
                    Erikastr 9<br />
                    D 66424 Homburg/ Saar <br />
                    Tel.:+49 (0)6841-2896
                    <br />E-Mail: <a class="text-Schwarz" href="mailto:afm-shop@mammillaria.eu">
                      afm-redaktion-mtbl@mammillaria.eu
                      </a>
                  </div>
                  <div class="col-3"></div>
                  <div class="col-3"></div>
                </div>
                <h3 v-if="sprachID === 1">special issues</h3>
                <p v-if="sprachID === 1">
                  For information on available special aids, please refer to the
                  Special Issues Page
                </p>
              </li>
              <li>
                <div class="container-fluid p-0 m-0">
                  <div class="row m-0 pt-5  ">
                    <div class="col-8 m-0 p-0">
                      <h2 v-if="sprachID === 0">Loseblattsammlung</h2>
                      <h2 v-if="sprachID === 1">loose-leaf collection</h2>
                    </div>
                  </div>
                  <div class="row m-0 p-0 ">
                    <div class="col-12 m-0 p-0">
                      <p v-if="sprachID === 0">
                        Die Herausgabe der Loseblattsammlung durch den AfM ist die Fortsetzung
                        der vorher durch die ZAG Mammillaria herausgegebenen 4 Lieferungen.
                      </p>
                      <p v-if="sprachID === 0">
                        Die Pflanzen werden in 2 Farbbildern dargestellt (Porträt und
                        Standortfoto). Eine Reproduktion der Abbildung in der Erstbeschreibung
                        ist beigefügt, und die Samen werden in meist 3 REM-Fotos abgebildet.
                      </p>
                      <p v-if="sprachID === 1">
                        The publication of the loose-leaf collection by the AfM is the continuation
                        of the 4 deliveries previously published by the ZAG Mammillaria.
                      </p>
                      <p v-if="sprachID === 1">
                        The plants are presented in 2 color images (portrait and
                        location photo). A reproduction of the illustration in the first description
                        is attached, and the seeds are shown in mostly 3 SEM photos.
                      </p>
                    </div>
                  </div>
                </div>
                
                <div class="row p-0 mb-3">
                  <div class="col-12 col-md-3 p-0">Redakteur: <br />(zwischenzeitlich):</div>
                  <div class="col-12 col-md-6 p-0">
                    Othmar Appenzeller <br />
                    Erikastr 9<br />
                    D 66424 Homburg/ Saar <br />
                    Tel.:+49 (0)6841-2896
                    <br />E-Mail: <a class="text-Schwarz" href="mailto:afm-shop@mammillaria.eu">
                      afm-redaktion-mtbl@mammillaria.eu
                      </a>
                  </div>
                </div>
                <div class="row p-0 mb-3">
                  <div class="col-12 col-md-3 p-0">Redaktionelle Mitarbeit:</div>
                  <div class="col-4">
                    Klaus Rebmann <br />
                   <br />
                    Holger Rudzinski <br />
                    
                  </div>
                </div>
              </li>
              <li>
                <h2 v-if="sprachID === 0">
                  Übersicht der verfügbaren Literaturen
                </h2>
                <div class="row">
                  <div class="col-md-3">
                    <ul class="noDeko">
                      <li
                        class="borderRund"
                        @click="openPDF('Vuepdf/Literaturpreisliste2023.pdf')"
                      >
                        <span v-if="sprachID === 0" class="bold"
                          >Literaturpreisliste 2023</span
                        >
                        <span v-if="sprachID === 1" class="bold"
                          >literature price list 2023</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="div stickyShop">
          <ul class="noDeko">
            <li class="borderRund p-3" @click="openBestellung()">
              <h2 v-if="sprachID === 0" class="bold">Bestellformular</h2>
              <h2 v-if="sprachID === 0" class="bold">Hier</h2>
              <h2 v-if="sprachID === 1" class="bold">Order form</h2>
              <h2 v-if="sprachID === 1" class="bold">Here</h2>
            </li>
          </ul>
        </div>

        <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
          <div class="col-11 col-md-8 m-0 p-0">
            <h3 v-if="sprachID === 0">Allgemeine Versandinformationen</h3>
            <p v-if="sprachID === 0">
              Bei Problemen mit dem Versand des Mitteilungsblattes wenden Sie
              sich bitte an den Schriftführer.
            </p>
            <h3 v-if="sprachID === 1">Allgemeine Versandinformationen</h3>
            <p v-if="sprachID === 1">
              If you have problems sending the newsletter, please contact us
              please to the secretary.
            </p>
            <p v-if="sprachID === 0">
              Anfragen und Bestellungen früherer Ausgaben richten Sie bitte an
              den Leiterin der Versandstelle. <br />
              Mit der Lieferung erhalten Sie eine Rechnung und Kontoangaben.
              <br />
              Porto und Verpackung wird zum Selbstkostenpreis berechnet.
            </p>
            <p v-if="sprachID === 1">
              Please direct inquiries and orders for previous issues to the Head
              of Shipping. <br />
              With the delivery you will receive an invoice and account details.
              <br />
              Postage and packaging will be charged at cost price.
            </p>
            <h3 v-if="sprachID === 0" class="pt-4">
              Bitte nur folgende Bankverbindung benutzen!
            </h3>
            <h3 v-if="sprachID === 1" class="pt-4">
              Please only use the following bank details!
            </h3>
            <p v-if="sprachID === 0">Zahlungen richten Sie bitte an</p>
            <p v-if="sprachID === 1">Please send payments to</p>
            <p class="ps-5">
              Arbeitskreis für Mammillarienfreunde e.V.<br />
              Sparkasse Westerwald-Sieg<br />
              IBAN: DE24 5735 1030 0050 0600 86<br />
              BIC: MALADE51AKI<br />
            </p>
            <p v-if="sprachID === 0">
              Nur für Mitglieder aus nicht EU-Länder:<br />
              Zahlungen an den AfM sind über PayPal möglich
            </p>
            <p v-if="sprachID === 1">
              Only for members from non-EU countries:<br />
              Payments to the AfM are possible via PayPal
            </p>
            <p></p>
            <p></p>
          </div>
        </div>

        <div class="row t-center justifyCenter pt-5 bg-WeissTransparent">
          <div class="col-md-3">
            <h4>
              <p v-if="sprachID === 0">Leiterin der Versandstelle:</p>
              <p v-if="sprachID === 1">Head of Shipping:</p>
            </h4>
            <P>
              <span class="bold">Marlies Schauer</span> <br />
              Bucha 1<br />
              D 94469 Deggendorf<br />
              Tel.: +49-(0)991-32454<br />

              E-Mail:
              <a class="text-Schwarz" href="mailto:afm-shop@mammillaria.eu"
                >afm-shop@mammillaria.eu
              </a>
            </P>
          </div>
          <div class="col-md-3">
            <h4>
              <p v-if="sprachID === 0">Kassierer:</p>
              <p v-if="sprachID === 1">cashier:</p>
            </h4>
            <P>
              <span class="bold">Joachim Chupik</span> <br />
              Leipzigerstr. 25<br />
              D 63607 Wächtersbach<br />
              Tel: +49(0)6053-601465<br />

              E-Mail:
              <a class="text-Schwarz" href="mailto:afm-kassierer@mammillaria.eu"
                >afm-kassierer@mammillaria.eu
              </a>
            </P>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Versand",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
