<template>
  <carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
    <slide v-for="slide in slides" :key="slide">
      <router-link
        to="/Praxis"
        ><img  :src="getImgUrl(slide)" v-bind:height="210" v-bind:width="250" 
      /></router-link>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "carouselErstbeschreibungen",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/Erstbeschreibungen/", false, /\.jpg$/);
     // console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      "e1",
      "e2",
      "e3",
      "e4",
      "e5",
      "e6",
      "e7",
      "e8",
      "e9",
      
    ];
    return {
      slides,
    };
  },
};
</script>
