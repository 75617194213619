<template>
  <div class="card text-vue" style="width: 802px; max-height: 85%">
    <div class="card-header text-center">
      <div class="ps-4">
        <h1 class="text-Rot-Schatten fw-bold">Bestellanfrage</h1>
      </div>
      <div class="ps-4">
        Sie haben Fragen oder Wünsche? Das Team des AfM ist gern für Sie da!
      </div>
    </div>
    <div class="card-body scroll p-0 m-0">
      <div class="container-fluid p-0 m-0">
        <div class="row p-0 m-0 bg-Hellgrau">
          <div class="col-md-12 p-0 m-0 text-Weiss bg-Hellgrau">
            <div class="container py-4">
              <!-- Name input -->

              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label" for="emailVorname">Vorname*</label>
                  <input
                    class="form-control"
                    type="text"
                    name="vorname"
                    placeholder="Kaktus"
                    v-model="mail.vorname"
                    v-bind:class="background.vorname"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label" for="emailNachname"
                    >Nachname*</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    name="nachname"
                    placeholder="Mammillaria"
                    v-model="mail.nachname"
                    v-bind:class="background.nachname"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-10">
                  <label class="form-label" for="emailStrasse">Strasse*</label>
                  <input
                    class="form-control"
                    type="text"
                    name="strasse"
                    placeholder="Blumentopf"
                    v-model="mail.strasse"
                    v-bind:class="background.strasse"
                  />
                </div>
                <div class="col-md-2">
                  <label class="form-label" for="emailNummer">Nummer*</label>
                  <input
                    class="form-control"
                    type="text"
                    name="nummer"
                    placeholder="2a"
                    v-model="mail.nummer"
                    v-bind:class="background.nummer"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-2">
                  <label class="form-label" for="emailPLZ">PLZ*</label>
                  <input
                    class="form-control"
                    type="text"
                    name="plz"
                    placeholder="01234"
                    v-model="mail.plz"
                    v-bind:class="background.plz"
                  />
                </div>
                <div class="col-md-10">
                  <label class="form-label" for="emailOrt">Ort*</label>
                  <input
                    class="form-control"
                    type="text"
                    name="ort"
                    placeholder="Gewächshaus"
                    v-model="mail.ort"
                    v-bind:class="background.ort"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-10">
                  <label class="form-label" for="emailOrt">Land</label>
                  <input
                    class="form-control"
                    type="text"
                    name="ort"
                    placeholder="Deutschland"
                    v-model="mail.land"
                    v-bind:class="background.land"
                  />
                </div>
              </div>

              <!-- Email address input -->
              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label" for="emailAddress"
                    >Email Adresse*</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    name="email"
                    placeholder="max@example.de"
                    v-model="mail.email"
                    v-bind:class="background.email"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label" for="emailAddress">Telefon</label>
                  <input
                    class="form-control"
                    type="text"
                    name="telefon"
                    placeholder="03671 12 34 56"
                    v-model="mail.telefon"
                    v-bind:class="background.telefon"
                  />
                </div>
              </div>

              <!-- Message input -->
              <div class="mb-3">
                <label class="form-label" for="message">Bestellanfrage*</label>
                <textarea
                  class="form-control"
                  id="message"
                  type="text"
                  placeholder="Ihre Bestellanfrage bei uns"
                  style="height: 10rem"
                  v-model="mail.message"
                  v-bind:class="background.message"
                ></textarea>
              </div>

              <div class="col-md-10 mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="andere"
                  v-model="mail.datenschutz"
                  v-bind:class="background.datenschutz"
                  required
                />
                <label class="form-check-label" for="andere"
                  >&nbsp; Ich habe die
                  <a
                    class="text-Weiss text-decoration-underline"
                    href="/Datenschutz"
                    >Datenschutzerklärung</a
                  >
                  zur Kenntnis genommen.
                </label>
                <label class="form-check-label inputerror"> </label>
              </div>
              <div class="col-md-10 mb-3">
                <p>
                  Mit der Lieferung erhalten Sie eine Rechnung und Kontoangaben.
                  Porto und Verpackung wird zum Selbstkostenpreis berechnet.
                </p>
              </div>

              <!-- Form submit button -->
              <div class="d-grid">
                <button
                  class="btn btn-lg"
                  @click="sendMail()"
                  type="submit"
                  :class="background.gesendet"
                >
                  <span v-if="mail.fehler == true"
                    >Unvollständige Eingabe, bitte prüfen!
                  </span>
                  <span >{{mail.gesendet}}  
                  </span>
                  Absenden
                </button>
              </div>
              <!--</form>-->
              <div class="col-md-12 mb-3">
                <div class="row">
                  <div class="col-12 text-end">
                    <div class="row">
                      <div class="col-md-10">
                        Arbeitskreis für Mammillarienfreunde e.V.<br />
                        Uwe Lehmann Vorsitzender<br />
                        Alte Poststr. 48<br />
                        D 01471 Radeburg
                      </div>

                      <div class="col-md-2"></div>
                    </div>
                    <div class="row pt-3">
                      <div class="col-md-10">
                        <a class="text-Schwarz" href="tel:035208-4765"
                          >035208-4765</a
                        >
                      </div>
                      <div class="col-md-2">
                        <i class="fas fa-phone" style="font-size: 18px"></i>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-10">
                        <a
                          class="text-Schwarz"
                          href="mailto:afm-shop@mammillaria.eu"
                          >afm-shop@mammillaria.eu</a
                        >
                      </div>
                      <div class="col-md-2">
                        <i
                          class="fas fas fa-envelope"
                          style="font-size: 18px"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" @click="close()">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import { closeModal } from "jenesius-vue-modal";
import axios from "axios";

export default {
  name: "ModalInhalsverzeichnis",
  data() {
    return {
      newSite: null,
      mail: {
        vorname: "",
        nachname: "",
        strasse: "",
        nummer: "",
        plz: "",
        ort: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: false,
        gesendet: "",
        fehler: false,
      },
      background: {
        vorname: "",
        nachname: "",
        strasse: "",
        plz: "",
        ort: "",
        nummer: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: "",
        gesendet: "bg-Rot text-Weiss",
      },
    };
  },
  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.vorname == "") {
        this.background.vorname = "border border-2 border-danger";
        console.log("vorname");
        re = false;
      } else {
        this.background.vorname = "border border-2 border-success";
      }

      if (this.mail.nachname == "") {
        this.background.nachname = "border border-2 border-danger";
        console.log("nachname");
        re = false;
      } else {
        this.background.nachname = "border border-2 border-success";
      }
      if (this.mail.strasse == "") {
        this.background.strasse = "border border-2 border-danger";
        console.log("strasse");
        re = false;
      } else {
        this.background.strasse = "border border-2 border-success";
      }

      if (this.mail.nummer == "") {
        this.background.nummer = "border border-2 border-danger";
        console.log("nummer");
        re = false;
      } else {
        this.background.nummer = "border border-2 border-success";
      }

      if (this.mail.plz == "") {
        this.background.plz = "border border-2 border-danger";
        console.log("plz");
        re = false;
      } else {
        this.background.plz = "border border-2 border-success";
      }

      if (this.mail.ort == "") {
        this.background.ort = "border border-2 border-danger";
        console.log("ort");
        re = false;
      } else {
        this.background.ort = "border border-2 border-success";
      }

      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        console.log("email");
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }

      if (this.mail.message == "") {
        this.background.message = "border border-2 border-danger";
        console.log("message");
        re = false;
      } else {
        this.background.message = "border border-2 border-success";
      }

      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        console.log("datenschutz");
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }
      if (re == false) {
        this.background.gesendet="bg-warning"
        this.mail.fehler = true;
      } else {
        this.mail.fehler = false;
        this.background.gesendet="bg-Rot text-Weiss"
      }
      return re;
    },

    sendMail() {
      console.log(this.checkEingaben());
      if (this.checkEingaben()) {
        console.log("Test");
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "afm-shop@mammillaria.eu",
          //empfaenger: "joerg@pillar.de",
          Vorname: this.mail.vorname,
          Nachname: this.mail.nachname,
          Strasse: this.mail.strasse,
          PLZ: this.mail.plz,
          Ort: this.mail.ort,
          Nummer: this.mail.nummer,
          Telefon: this.mail.telefon,
          Email: this.mail.email,
          Grund: "Onlinebestellung",
          Text: this.mail.message,
          Datenschutz: this.mail.datenschutz,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post(
            "https://pillar.de/afm/post_attachment.php",
            payload,
            axiosConfig
          )
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Bestellanfrage versendet! - ";
            background.gesendet="bg-success";

            mail.vorname = "";
            background.vorname = "";

            mail.nachname = "";
            background.nachname = "";

            mail.strasse = "";
            background.strasse = "";

            mail.nummer = "";
            background.nummer = "";

            mail.plz = "";
            background.plz = "";

            mail.ort = "";
            background.ort = "";

            mail.email = "";
            background.email = "";

            mail.telefon = "";
            background.telefon = "";

            mail.message = "";
            background.message = "";

            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },
    close() {
      closeModal();
    },
  },
};
</script>
<style>
label {
  text-align: start !important;
}
</style>
