<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1" >
        <div class="row bg-Hellgrau  justifyCenter " id="t0">
          
          
            <iframe width="100%" height="995" src="http://joerg21.magix.net" frameborder="0" class="p-0"></iframe>
          
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Praxis",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
