<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0">
        <div class="row praxisBackground text-Dunkelgrau">
          <div class="col-md-8 offset-md-2 mt-5 pt-4 align-items-center ps-5" v-if="sprachID === 0">
            <h1>Datenschutzerklärung</h1>

            <h3>1. Datenschutz auf einen Blick</h3>
            <h4>Allgemeine Hinweise</h4>
            <p>
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </p>
            <p>Datenerfassung auf unserer Website</p>
            <p>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </p>
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
              dieser Website entnehmen. Wie erfassen wir Ihre Daten? Ihre Daten
              werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
              Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
              Kontaktformular eingeben. Andere Daten werden automatisch beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z.B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie unsere Website betreten.
            </p>
            <p>Wofür nutzen wir Ihre Daten?</p>
            <p>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Ihre Daten werden nur
              zum reibungslosen Ablauf innerhalb des Vereins wie z.B.
              Beitragserhebung, Ehrungen, Versand des Mitteilungsblattes und der
              Loseblattsammlung oder ähnliche vereinsinterne Zwecke verwendet.
            </p>
            <p> Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
            <p> Sie haben jederzeit  das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck
            Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
            außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser
            Daten zu verlangen. Dies bedeutet aber gleichzeitig einen Austritt
            aus dem Verein außer bei einer Berichtigung. Hierzu sowie zu
            weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
            unter der im Impressum angegebenen Adresse an uns wenden. Des
            Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
            Aufsichtsbehörde zu.</p>
            <h3>  2. Allgemeine Hinweise und Pflichtinformationen</h3>
            <p>Datenschutz</p>
            <p> Die Betreiber dieser Seiten nehmen den Schutz Ihrer
            persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen
            Daten vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.</p>
            <p>Hinweis zur verantwortlichen Stelle</p>
            <p>  Die verantwortliche
            Stelle für die Datenverarbeitung ist der Afm vertreten durch den
            aktuellen Vorsitzenden.<br /> <a href="afm-datenschutz@mammillaria.eu"> Mail: afm-datenschutz@mammillaria.eu</a><br />
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet. Auskunft, Sperrung, Löschung Sie
            haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
            das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
            personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
            der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
            oder Löschung dieser Daten.</p>
            <p>Hierzu sowie zu weiteren Fragen zum
            Thema personenbezogene Daten können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden.</p>
            <p>Widerspruch gegen
            Werbe-Mails</p>
            <p>Es werden vom AfM keinerlei Werbe-Mails verschickt. Der
            Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-EMails, vor.</p>
            <h3>3. Datenerfassung auf unserer Website</h3>
            <p> Beim Besuch der Seite werden keinerlei Cookies geschaltet.</p>
            <p>Server-Log-Dateien</p>
            <p>
              Der Provider der Seiten erhebt und speichert
            automatisch Informationen in so genannten Server-Log-Dateien, die
            Ihr Browser automatisch an uns übermittelt. Dies sind: 
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
              <li>IP-Adresse</li>
            </ul>
            </p>
            <p >Eine Zusammenführung dieser Daten mit anderen
            Datenquellen wird nicht vorgenommen. Grundlage für die
            Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die
            Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet.</p>
            <p></p>
            <p></p>
            <p></p>
            <p>Bildernachweis:</p>
            <p><a href="https://www.flaticon.com/de/kostenlose-icons/vereinigte-staaten" title="vereinigte staaten Icons">Vereinigte staaten Icons erstellt von Freepik - Flaticon</a></p>
            <p><a href="https://www.flaticon.com/de/kostenlose-icons/deutschland" title="deutschland Icons">Deutschland Icons erstellt von Freepik - Flaticon</a></p>
          
                
            
          </div>
          
          <div class="col-md-8 offset-md-2 mt-5 pt-4 align-items-center ps-5"  v-if="sprachID === 1">
            <h1>Privacy Policy</h1>

            <h3>1. Data protection at a glance</h3>
            <h4>General notes</h4>
            <p>
              The following notes provide a simple overview of
              what happens to your personal data when you use our
              visit site. Personal data is all data with
              which you can be personally identified. detailed
              Information on the subject of data protection can be found in our at
              privacy policy listed in this text.
            </p>
            <p>Data collection on our website</p>
            <p>
              Who is responsible for data collection on this website?
            </p>
            <p>
              The data processing on this website is carried out by the
              website operator. You can find their contact details in the imprint
              refer to this website. How do we collect your data? your data
              are collected on the one hand by the fact that you communicate them to us.
              This can, for example, be data that you enter in a
              Enter contact form. Other dates are automatically when
              visit to the website is recorded by our IT systems. These are before
              All technical data (e.g. Internet browser, operating system or
              time of page view). This data is recorded
              automatically as soon as you enter our website.
            </p>
            <p>What do we use your data for?</p>
            <p>
              Part of the data is collected to ensure error-free
              to ensure the provision of the website. Your data will only
              to ensure that everything runs smoothly within the association, e.g.
              Collection of contributions, honors, mailing of the newsletter and the
              loose-leaf collection or similar internal purposes.
            </p>
            <p> What are your rights regarding your data?</p>
            <p> You have the right to information about the origin, recipient and purpose free of charge at any time
            to receive your stored personal data. They have
            you also have the right to have them corrected, blocked or deleted
            to request data. At the same time, this means an exit
            from the club except for a correction. For this as well as to
            You can ask further questions about data protection at any time
            contact us at the address given in the imprint. Of
            You also have the right to lodge a complaint with the competent authority
            supervisory authority.</p>
            <h3> 2. General information and mandatory information</h3>
            <p>Privacy Policy</p>
            <p> The operators of these pages take the protection of your
            personal information very seriously. We treat your personal
            Data confidential and in accordance with the law
            data protection regulations and this data protection declaration. If you
            using this website will collect various personal data
            raised. Personal data is data with which you are personally
            can be identified. This privacy policy
            explains what data we collect and what we use it for. she
            also explains how and for what purpose this happens. We point
            points out that data transmission on the Internet (e.g. with the
            communication via e-mail) may have security gaps. A
            Complete protection of data from access by third parties is not
            possible.</p>
            <p>Note on the responsible body</p>
            <p> The one in charge
            The body responsible for data processing is the Afm, represented by the
            current chairman.<br /> <a href="afm-datenschutz@mammillaria.eu"> Mail: afm-datenschutz@mammillaria.eu</a><br />
            The responsible body is the natural or legal person
            who alone or jointly with others about the ends and means of
            Processing of personal data (e.g. names,
            e-mail addresses or similar) decides. Information, blocking, deletion
            have at any time within the framework of the applicable legal provisions
            the right to free information about your stored data
            personal data, their origin and recipients and the purpose
            of data processing and, if applicable, a right to correction or blocking
            or deletion of this data.</p>
            <p>For this and other questions about
            You can contact the subject of personal data at any time at im
            Please contact us at the address given in the imprint.</p>
            <p>Objection to Promotional emails</p>
            <p>The AfM does not send any advertising mails. Of the
            Use of published within the scope of the imprint obligation
            Contact details for sending unsolicited
            Advertising and information materials are hereby contradicted. the
            Operators of the pages expressly reserve the right to take legal action
            in the case of unsolicited sending of advertising information, e.g
            through spam emails.</p>
            <h3>3. Data collection on our website</h3>
            <p> No cookies are set when you visit the site.</p>
            <p>Server log files</p>
            <p>
              The provider of the pages collects and stores
            automatically information in so-called server log files that
            Your browser automatically sent to us. These are:
            <ul>
              <li>Browser type and browser version</li>
              <li>Operating system used</li>
              <li>Referrer URL</li>
              <li>Hostname of the accessing computer</li>
              <li>Time of server request</li>
              <li>IP address</li>
            </ul>
            </p>
            <p>A merge of this data with others
            Data sources is not made. basis for the
            Data processing is Article 6 Paragraph 1 Letter f GDPR, which
            Processing of data to fulfill a contract or
            pre-contractual measures permitted.</p>
            <p></p>
            <p></p>
            <p></p>
            <p>Picture credits:</p>
            
            <p><a href="https://www.flaticon.com/en/free-icons/united-states" title="united states Icons">United states Icons created by Freepik - Flaticon</a></p>
            <p><a href="https://www.flaticon.com/en/free-icons/germany" title="germany icons">Germany icons created by Freepik - Flaticon</a></p>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Datenschutz",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
