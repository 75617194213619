<template #default>
  <div class="container-fluid p-0 m-0 background1">
    <div class="row bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-6  mt-5 p-0">
        <h1 v-if="sprachID === 0">Befreundete Seiten</h1>
        <h1 v-if="sprachID === 1">Friendly Pages</h1>
      </div>
    </div>
    <div class="row bg-WeissTransparent pb-5 justifyCenter">
      <div class="col-11 col-md-6">
        <table width="100%" cellspacing="5">
         
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>Appenzeller, Othmar (Literatur)</td>
            <td>
              <a href="mailto:othmar49@aol.de">othmar49@aol.de</a>
            </td>
          </tr>          
          <tr>
            <td>Brack, Steven</td>
            <td>
              <a href="http://www.mesagarden.com" target="_new"
                >www.mesagarden.com</a
              >
            </td>
          </tr>
          <tr>
            <td>Davies Chris</td>
            <td>
              <a href="http://www.woodedge.co.uk/" target="_new">
                www.woodedge.co.uk
              </a>
            </td>
          </tr>
          <tr>
            <td>Dehn, Ralf</td>
            <td>
              <a href="http://www.botanik.uni-halle.de/" target="_new">
                www.botanik.uni-halle.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Dicht, Retho</td>
            <td>
              <a href="http://mypage.bluewin.ch/retodicht/" target="_new">
                www.mypage.bluewin.ch/retodicht
              </a>
            </td>
          </tr>
          <tr>
            <td>DKG OG Leipzig, Astrophytum Leipzig e.V.</td>
            <td>
              <a href="http://www.astrophytum-leipzig.de" target="_new">
                www.astrophytum-leipzig.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Freiburger Kakteenfreunde</td>
            <td>
              <a href="http://www.freiburger-kakteenfreunde.de" target="_new">
                www.freiburger-kakteenfreunde.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Gutte, Dr. Gottfried</td>
            <td>
              <a href="http://www.cactusbooks.com" target="_new">
                www.cactusbooks.com
              </a>
            </td>
          </tr>
          <tr>
            <td>Hanauer Kakteenfreunde</td>
            <td>
              <a href="http://www.kakteenfreunde-mkk.de/ " target="_new">
                www.kakteenfreunde-mkk.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Hüser, Jürgen</td>
            <td>
              <a href="http://www.juergens-kakteen.de" target="_new">
                www.juergens-kakteen.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Andreae Kakteenkulturen</td>
            <td>
              <a href="http://www.kaktusmichel.de" target="_new">
                www.kaktusmichel.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Kakteen-Sukkulenten Lipp + Meier</td>
            <td>
              <a href="http://www.mexiflor.net" target="_new">
                www.mexiflor.net
              </a>
            </td>
          </tr>

          <tr>
            <td>Kakteenforum</td>
            <td>
              <a href="http://www.kakteenforum.com" target="_new">
                www.kakteenforum.com
              </a>
            </td>
          </tr>
          <tr>
            <td>Knees, Rudolf</td>
            <td>
              <a href="http://www.kakteenreppenhagen.cjb.net " target="_new">
                www.kakteenreppenhagen.cjb.net
              </a>
            </td>
          </tr>
          <tr>
            <td>K&ouml;hres, Gerhard</td>
            <td>
              <a href="http://www.koehres-kaktus.de" target="_new">
                www.koehres-kaktus.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Lochner, Andreas</td>
            <td>
              <a href="http://www.cinerea.de" target="_new"> www.cinerea.de </a>
            </td>
          </tr>
          <tr>
            <td>Matuszewski, Grzegorz</td>
            <td>
              <a href="http://www.kaktusymeksyku.pl" target="_new">
                www.kaktusymeksyku.pl
              </a>
            </td>
          </tr>

          <tr>
            <td>Martin, Franck</td>
            <td>
              <a href="http://www.mammillarias.net " target="_new">
                www.Mammillarias.net
              </a>
            </td>
          </tr>
          <tr>
            <td>Meier, Gerhard</td>
            <td>
              <a href="http://www.ferocactus.org/" target="_new">
                www.ferocactus.org/
              </a>
            </td>
          </tr>
          <tr>
            <td>Meier, Gerhard</td>
            <td>
              <a href="http://www.mexiflor.net/" target="_new">
                www.mexiflor.net/
              </a>
            </td>
          </tr>
          <tr>
            <td>Meier, Gerhard</td>
            <td>
              <a href="http://www.ariocarpus.info" target="_new">
                www.ariocarpus.info
              </a>
            </td>
          </tr>
          <tr>
            <td>Nitzschke, Stefan</td>
            <td>
              <a href="http://www.turbinicarpus.net" target="_new">
                www.turbinicarpus.net
              </a>
            </td>
          </tr>
          <tr>
            <td>Piltz, J&ouml;rg</td>
            <td>
              <a href="http://www.kakteen-piltz.de" target="_new">
                www.kakteen-piltz.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Schattke, Konrad</td>
            <td>
              <a href="http://www.mammillaria.de" target="_new">
                www.mammillaria.de
              </a>
            </td>
          </tr>
          <tr>
            <td>SKG OG Solothurn</td>
            <td>
              <a href="http://www.kaktusverein.ch" target="_new">
                www.kaktusverein.ch
              </a>
            </td>
          </tr>
          <tr>
            <td>Sukkulenten-Sammlung Z&uuml;rich</td>
            <td>
              <a href="http://www.sukkulenten.ch" target="_new">
                www.sukkulenten.ch
              </a>
            </td>
          </tr>
          <tr>
            <td>VKW</td>
            <td>
              <a href="http://www.vkw-kakteen.de" target="_new"
                >www.vkw-kakteen.de</a
              >
            </td>
          </tr>
          <tr>
            <td>Wei&szlig;, Gerd</td>
            <td>
              <a href="http://www.ruegenkaktus-weiss.de/" target="_new">
                www.ruegenkaktus-weiss.de
              </a>
            </td>
          </tr>
          <tr>
            <td>Z&uuml;rcher Kakteengesellschaft</td>
            <td>
              <a href="http://www.skg-zuerich.ch" target="_new">
                www.skg-zuerich.ch
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Satzung",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
