<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten text-center">
        <h1 v-if="sprachID === 0">Protokolle der Jahreshauptversammlungen</h1>
        <h1 v-if="sprachID === 1">Minutes of the annual general meetings</h1>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-6 m-0 pt-5">
        <p>2023</p>
        <ul class="noDeko">
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2023/Protokoll_JHV_2023.pdf')"
          >
            <span class="bold">Protokoll</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2023/ProtokollVorstandssitzungJHV_2023.pdf')"
          >
            <span class="bold">Protokoll Vorstandsitzung</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2023/Anwesenheitsliste_2023-04.pdf')"
          >
            <span class="bold">Anwesenheitsliste</span>
          </li>          
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2023/Bericht Schriftfuehrer-2022.pdf')"
          >
            <span class="bold">Bericht Schriftführer</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2023/Kassenbericht_2022.pdf')"
          >
            <span class="bold">Bericht Kasse</span>
          </li>          
        </ul>

        <p>2022</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2022/Protokoll.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2022/Rechenschaftsbericht des Vorsitzenden.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2022/Bericht Schriftführer.pdf')"
          >
            <span class="bold">Bericht Schriftführer</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2022/Bericht des Redakteurs 2022 JHV.pdf')"
          >
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2022/Tätigkeitsberich REM 2021.pdf')"
          >
            <span class="bold">Bericht REM</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2022/Tätigkeitsbericht Bibliothek.pdf')"
          >
            <span class="bold">Bericht Bibliothek</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2022/Tätigkeitsbericht Samenfond.pdf')"
          >
            <span class="bold">Bericht Samenfond</span>
          </li>
        </ul>

        <p>2021</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Protokoll.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 1.pdf')">
            <span class="bold">Anwesenheitsliste Mitgliederversammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 2.pdf')">
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 3.pdf')">
            <span class="bold">Bericht Schriftführer</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 4.pdf')">
            <span class="bold">Bericht der Kasse 2020</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 5.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 6.pdf')">
            <span class="bold">Bericht Bibliothek</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 7.pdf')">
            <span class="bold">Bericht der Planzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 8.pdf')">
            <span class="bold">Bericht der Samenfond</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 9.pdf')">
            <span class="bold">Bericht REM - Archiv</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2021/Anlage 10.pdf')">
            <span class="bold">Erklärung Manfred Bruse</span>
          </li>
        </ul>

        <p>2020</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/')">
            <span class="bold">Coronabedingt ausgefallen</span>
          </li>
        </ul>

        <p>2019</p>
        <ul class="noDeko">
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2019/Protokoll_27_4_2019.pdf')"
          >
            <span class="bold">Protokoll</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2019/2018redakteur.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2019/2019bibliothek.pdf')">
            <span class="bold">Bericht Bibliothek</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2019/2018rem.pdf')">
            <span class="bold">Bericht REM - Archiv</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2019/2018pflanzensammlung.pdf')"
          >
            <span class="bold">Bericht der Planzensammlung</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2019/2018erstbeschreibungen.pdf.pdf')"
          >
            <span class="bold">Bericht der Erstbeschreibungen</span>
          </li>
        </ul>

        <p>2018</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/ProtokollJHV2018.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2018/RechenschaftsberichtdesVorsitzenden.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Schriftfuehrer.pdf')">
            <span class="bold">Bericht Schriftführer</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Redakteur.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Bibliothek.pdf')">
            <span class="bold">Bericht Bibliothek</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/REM.pdf')">
            <span class="bold">Bericht REM - Archiv</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Pflanzensammlung.pdf')">
            <span class="bold">Bericht der Planzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Samenfond.pdf')">
            <span class="bold">Bericht der Samenfond</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Kasse2015.pdf')">
            <span class="bold">Bericht der Kasse 2015 - 2016</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Kasse2017.pdf')">
            <span class="bold">Bericht der Kasse 2017</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2018/Kassenpruefung.pdf')">
            <span class="bold">Bericht der Kassenprüfung</span>
          </li>
        </ul>

        <p>2017</p>
        <p>Berichte zur außerordentlichen Jahreshauptversammlung</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/ausserordendliche.pdf')">
            <span class="bold"
              >Protokoll der außerordentlichen Jahreshauptversammlung AfM</span
            >
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2017/Kassenpruefbericht.pdf')"
          >
            <span class="bold">Kassenprüfbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Kassenbericht.pdf')">
            <span class="bold">Kassenbericht</span>
          </li>
        </ul>

        <p>Berichte zur 40.Jahreshauptversammlung 2017</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Protokoll2017.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2017/ProtokerwVorstands.pdf')"
          >
            <span class="bold">Protokoll erweiterte Vorstandsitzung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Vorsitzender.pdf')">
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Schriftfuehrer.pdf')">
            <span class="bold">Bericht Schriftführer</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Redakteur.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Bibliothek.pdf')">
            <span class="bold">Bericht Bibliothek</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/REM.pdf')">
            <span class="bold">Bericht REM - Archiv</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Pflanzensammlung.pdf')">
            <span class="bold">Bericht der Planzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2017/Versand.pdf')">
            <span class="bold">Bericht des Versand</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2017/25JahreMitgliedschaft.pdf')"
          >
            <span class="bold">Ehrung langjähriger Mitglieder</span>
          </li>
        </ul>

        <p>2016</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Protokoll2016.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Vorsitzender.pdf')">
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Kassierer.jpg')">
            <span class="bold">Kassenbericht Kassierer 1</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Kassierer2.jpg')">
            <span class="bold">Kassenbericht Kassierer 2</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Kassenprüfung.pdf')">
            <span class="bold">Kassenprüfbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Redakteur.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Bibliothek.pdf')">
            <span class="bold">Bericht Bibliothek</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2016/Erstbeschreibungen.pdf')"
          >
            <span class="bold">Bericht Erstbeschreibungen</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/LBS.pdf')">
            <span class="bold">Bericht LBS</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Pflanzensammlung.pdf')">
            <span class="bold">Bericht Pflanzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/REM.pdf')">
            <span class="bold">Bericht REM - Archiv</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2016/Samenfond.jpg')">
            <span class="bold">Bericht Samenfond</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2016/25JahreMitgliedschaft.pdf')"
          >
            <span class="bold">Ehrung langjähriger Mitglieder</span>
          </li>
        </ul>

        <p>2015</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2015/Protokoll2015.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2015/Protokoll2-5-2015.pdf')">
            <span class="bold">Protokoll 02.05.2015</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2015/Vorsitzender.pdf')">
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2015/Kassenbericht.jpg')">
            <span class="bold">Kassenbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2015/Redakteur.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2015/LBS.pdf')">
            <span class="bold">Bericht LBS</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2015/Pflanzensammlung.pdf')">
            <span class="bold">Bericht Pflanzensammlung</span>
          </li>
        </ul>

        <p>2014</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2014/Programm.pdf')">
            <span class="bold">Programm der JHV</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2014/Protokoll2014.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2014/Vorsitzender.pdf')">
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2014/RechenschaftSchriftfuehrer.jpg')"
          >
            <span class="bold">Bericht des Schriftführers</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2014/Kassenbericht.jpg')">
            <span class="bold">Kassenbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2014/Redakteur.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2014/Erstbeschreibungen.pdf')"
          >
            <span class="bold">Bericht Erstbeschreibungen</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2014/Pflanzensammlung.pdf')">
            <span class="bold">Bericht Pflanzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2014/Samenfond.jpg')">
            <span class="bold">Bericht Samenfond</span>
          </li>
        </ul>

        <p>2013</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Programm.pdf')">
            <span class="bold">Programm der JHV</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/ProtokollHalle.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Vorsitzender.pdf')">
            <span class="bold">Tätigkeitsbericht Vorsitzender</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Schriftfuehrers.pdf')">
            <span class="bold">Bericht des Schriftführers</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Kassenbericht.pdf')">
            <span class="bold">Kassenbericht</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2013/Kassenpruefbericht.pdf')"
          >
            <span class="bold">Kassenprüfbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Redakteur.pdf')">
            <span class="bold">Bericht Redakteur</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Buecherei.pdf')">
            <span class="bold">Bericht Bücherei</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2013/Erstbeschreibungen.pdf')"
          >
            <span class="bold">Bericht Erstbeschreibungen</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/LBS.pdf')">
            <span class="bold">Bericht LBS</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Pflanzensammlung.pdf')">
            <span class="bold">Bericht Pflanzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/REM.pdf')">
            <span class="bold">Bericht REM - Archiv</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2013/Samenfond.pdf')">
            <span class="bold">Bericht Samenfond</span>
          </li>
        </ul>

        <p>2012</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/programm.pdf')">
            <span class="bold">Programm der JHV</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/Rahmenprogramm.pdf')">
            <span class="bold">Rahmenprogramm der JHV</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/protokoll.pdf')">
            <span class="bold">Protokoll</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/Vorsitzender.pdf')">
            <span class="bold">Tätigkeitsbericht des Vorsitzenden</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/Schriftfuehrer.pdf')">
            <span class="bold">Tätigkeitsbericht des Schriftführers</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/Kassenbericht.pdf')">
            <span class="bold">Kassenbericht</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2012/Kassenpruefbericht.pdf')"
          >
            <span class="bold">Kassenprüfbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/Redakteur.pdf')">
            <span class="bold">Tätigkeitsbericht des Redakteurs</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/Pflanzensammlung.pdf')">
            <span class="bold">Tätigkeitsbericht AfM-Pflanzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2012/REM.pdf')">
            <span class="bold">Tätigkeitsbericht REM</span>
          </li>
        </ul>
        <p>2011</p>
        <p>Berichte zur Vorstandssitzung 14.10.2011</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2011/Protokoll141011.pdf')">
            <span class="bold"
              >Protokoll der a.o. Mitgliederversammlung am 14.10.2011</span
            >
          </li>
        </ul>
        <p>Berichte zur Jahreshauptversammlung 2011</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2011/ProtokollderJHV.pdf')">
            <span class="bold">Protokoll der 34.JHV des AfM</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2011/Programm34JHV.pdf')">
            <span class="bold">Programm der 34.JHV</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/Bericht Vorsitzenderx.pdf')"
          >
            <span class="bold">Tätigkeitsbericht des Vorsitzenden</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2011/Kassenbericht.pdf')">
            <span class="bold">Kassenbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2011/BerichtRedakteur.pdf')">
            <span class="bold">Rechenschaftsbericht des Redakteurs</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/TaetigkeitsberichtBuecherei2011.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Bücherei</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/TaetigkeitsberichtLBS2010.pdf')"
          >
            <span class="bold">LBS_Tätigkeitsbericht</span>
          </li>
          <li
            class="borderRund"
            @click="
              openPDF('Vuepdf/jhv2011/TaetigkeitsberichtAfM-Pflanzensammlung2010.pdf')
            "
          >
            <span class="bold">Tätigkeitsbericht AfM-Pflanzensammlung</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/TaetigkeitsberichtREM.pdf')"
          >
            <span class="bold">Tätigkeitsbericht REM</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/AfM_Litauswert2010x.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Literaturauswertung</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/BerichtHefteversand.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Heftversand</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/JHV-Bericht-Sonderhefte-oa.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Sonderhefte</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2011/BerichtSchriftfuehrer.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Schriftführer</span>
          </li>
        </ul>

        <p>2010</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Protokoll.pdf')">
            <span class="bold">Protokoll der 33.JHV des AfM</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Vorsitzenden.pdf')">
            <span class="bold">Tätigkeitsbericht des Vorsitzenden</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Vorsitzenden.pdf')">
            <span class="bold">Kassenbericht</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2010/2010_Kassenbericht.jpg')"
          >
            <span class="bold">Kassenbericht</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2010/2010_Kassenpruefbericht.jpg')"
          >
            <span class="bold">Kassenprüfbericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Dr Hofmann.jpg')">
            <span class="bold">Bericht Dr.Hofmann</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2010/2010_Schiedsgericht.pdf')"
          >
            <span class="bold">Vortrag Schiedsgericht</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Redakteurs.pdf')">
            <span class="bold">Rechenschaftsbericht des Redakteurs</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Diathek.pdf')">
            <span class="bold">Tätigkeitsbericht Diathek</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Buecherei.pdf')">
            <span class="bold">Tätigkeitsbericht Bücherei</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_LBS.pdf')">
            <span class="bold">LBS_Tätigkeitsbericht</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2010/2010_AfM-Pflanzensammlung.pdf')"
          >
            <span class="bold">Tätigkeitsbericht AfM-Pflanzensammlung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_REM.pdf')">
            <span class="bold">Tätigkeitsbericht REM</span>
          </li>
          <li
            class="borderRund"
            @click="openPDF('Vuepdf/jhv2010/2010_Literaturauswertung.pdf')"
          >
            <span class="bold">Tätigkeitsbericht Literaturauswertung</span>
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Heftversand.pdf')">
            <span class="bold"
              >Tätigkeitsbericht HeftversandTätigkeitsbericht Heftversand</span
            >
          </li>
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2010/2010_Rogozinski.pdf')">
            <span class="bold">Ehrung Helmut Rogozinski</span>
          </li>
        </ul>

        <p>2009</p>
        <ul class="noDeko">
          <li class="borderRund" @click="openPDF('Vuepdf/jhv2009/jhv2009.pdf')">
            <span class="bold">Bericht der Jahreshauptversammlung</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Loseblatt",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
