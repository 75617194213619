<template class="">
  <div class="row footer pt-3 pb-3 m-0">
    <div class="col-md-2">
      <div class=" " style="height: 100%">
        <p class="text-center">
          
        </p>
        <p class="text-center">
          Kontaktieren Sie uns<br />
        </p>
        
      </div>
    </div>
    <div class="col-md-2">
      <div  style="height: 100%">
        
        
        <p class="text-center  fw-bold">
          <i class="fas fa-phone pe-3 fontSize16" ></i>
          <a class="text-Weiss" href="tel:0352084765" style="font-size: 16px">035208-4765</a>
          
        </p>
      </div>
    </div>
    <div class="col-md-2">
      <div style="height: 100%">
       
        
        <p class="text-center  fw-bold">
         <i class="fas fa-envelope pe-3 fontSize16" ></i>
          <a class="text-Weiss" href="mailto:afm-geschaeftsstelle@mammillaria.eu" style="font-size: 16px"
            >afm-geschaeftsstelle@mammillaria.eu</a
          >
        </p>
      </div>
    </div>
    <div class="col-md-2 col-sm-4 offset-md-2 offset-sm-0" >
      <router-link
        to="/Datenschutz"
        class="nav-link text-center"
        v-bind:class="[
          activeScreen == '/Datenschutz'
            ? 'navFooter-active'
            : 'navFooter-deactiv',
        ]"
        style="font-size: 16px"
      >
        Datenschutz</router-link
      >
    </div>
    <div class="col-md-2 col-sm-4 offset-0">
      <router-link
        to="/Impressum"
        class="nav-link text-center"
        v-bind:class="[
          activeScreen == '/Impressum'
            ? 'navFooter-active'
            : 'navFooter-deactiv',
        ]"
        style="font-size: 16px"
      >
        Impressum</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {},
  data() {
    return {
      activesite: "",
    };
  },
  created() {},
};
</script>

<style scoped></style>
